import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/home/web'),
  },
  {
    path: '/noPermissions',
    name: 'noPermissions',
    component: () => import('@/views/noPermissions/noPermissions'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/account/login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/account/register'),
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('@/views/market/market'),
  },
  {
    path: '/market/kline',
    name: 'market',
    component: () => import('@/views/market/kline'),
  },
  {
    path: '/market/second',
    name: 'market',
    component: () => import('@/views/market/second'),
  },
  {
    path: '/market/second-detail',
    name: 'market',
    component: () => import('@/views/market/recordDetail'),
  },
  {
    path: '/trade/trade',
    name: 'trade',
    component: () => import('@/views/trade/trade'),
  },
  {
    path: '/trade/entrust',
    name: 'trade',
    component: () => import('@/views/trade/entrust'),
  },
  {
    path: '/lever/lever',
    name: 'lever',
    component: () => import('@/views/lever/lever'),
  },
  {
    path: '/lever/leverList',
    name: 'lever',
    component: () => import('@/views/lever/leverList'),
  },
  {
    path: '/lever/orderList',
    name: 'lever',
    component: () => import('@/views/lever/orderList'),
  },
  {
    path: '/currency',
    name: 'currency',
    component: () => import('@/views/currency/currency'),
  },
  {
    path: '/assets',
    name: 'assets',
    component: () => import('@/views/assets/assets'),
  },
  {
    path: '/assets/recharge',
    name: 'assets',
    component: () => import('@/views/assets/recharge'),
  },
  {
    path: '/assets/withdraw',
    name: 'assets',
    component: () => import('@/views/assets/withdraw'),
  },
  {
    path: '/assets/transfer',
    name: 'assets',
    component: () => import('@/views/assets/transfer'),
  },
  {
    path: '/assets/record',
    name: 'assets',
    component: () => import('@/views/assets/record'),
  },
  {
    path: '/assets/record2',
    name: 'assets',
    component: () => import('@/views/assets/record2'),
  },
  {
    path: '/my/center',
    name: 'my',
    component: () => import('@/views/my/center'),
  },
  {
    path: '/my/identity',
    name: 'my',
    component: () => import('@/views/my/identity'),
  },
  {
    path: '/my/share',
    name: 'my',
    component: () => import('@/views/my/share'),
  },
  {
    path: '/my/empower',
    name: 'my',
    component: () => import('@/views/my/empower'),
  },
  {
    path: '/my/security',
    name: 'my',
    component: () => import('@/views/my/security'),
  },
  {
    path: '/my/accountPsd',
    name: 'my',
    component: () => import('@/views/my/accountPsd'),
  },
  {
    path: '/my/transPsd',
    name: 'my',
    component: () => import('@/views/my/transPsd'),
  },
  {
    path: '/my/collect',
    name: 'my',
    component: () => import('@/views/my/collect'),
  },
  {
    path: '/my/collect_money',
    name: 'my',
    component: () => import('@/views/my/collect_money'),
  },
  {
    path: '/my/about',
    name: 'my',
    component: () => import('@/views/my/about'),
  },
  {
    path: '/my/policy',
    name: 'my',
    component: () => import('@/views/my/policy'),
  },
  {
    path: '/my/workOrder',
    name: 'my',
    component: () => import('@/views/my/workOrder'),
  },
  {
    path: '/my/workRecord',
    name: 'my',
    component: () => import('@/views/my/workRecord'),
  },
  {
    path: '/my/help',
    name: 'my',
    component: () => import('@/views/my/help'),
  },
  {
    path: '/my/wallet',
    name: 'my',
    component: () => import('@/views/my/wallet'),
  },
  {
    path: '/my/add_wallet',
    name: 'my',
    component: () => import('@/views/my/add_wallet'),
  },
  {
    path: '/my/newsDetail',
    name: 'my',
    component: () => import('@/views/my/newsDetail'),
  },
  {
    path: '/legal/legal',
    name: 'legal',
    component: () => import('@/views/legal/legal'),
  },
  {
    path: '/legal/record',
    name: 'legal',
    component: () => import('@/views/legal/record'),
  },
  {
    path: '/deposit/index',
    name: 'deposit',
    component: () => import('@/views/deposit/index'),
  },
  {
    path: '/deposit/coinlog',
    name: 'deposit',
    component: () => import('@/views/deposit/coinlog'),
  },
  {
    path: '/deposit/confirm',
    name: 'deposit',
    component: () => import('@/views/deposit/confirm'),
  },
  {
    path: '/ieo/index',
    name: 'ieo',
    component: () => import('@/views/ieo/index'),
  },

  {
    path: '/loan/loan',
    name: 'loan',
    component: () => import('@/views/loan/loan'),
  },
  {
    path: '/loan/loan-list',
    name: 'loan',
    component: () => import('@/views/loan/loan-list'),
  },
  {
    path: '/loan/repay-list',
    name: 'loan',
    component: () => import('@/views/loan/repay-list'),
  },
  {
    path: '/my/message',
    name: 'message',
    component: () => import('@/views/my/message'),
  },
  {
    path: '/my/message-detail',
    name: 'message',
    component: () => import('@/views/my/messageDetail'),
  },
  {
    path: '/my/service',
    name: 'service',
    component: () => import('@/views/my/service'),
  },

]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}


const router = new VueRouter({
  routes
})

export default router
