export default {
    addNew: {
        my_wallet: "Мой бумажник.",
        add_wallet: "Добавить бумажник",
        place_wallet_type: "Выберите тип кошелька",
        place_wallet_name: "Введите имя кошелька",
        place_wallet_address: "Введите адрес кошелька",
        reference_annualization: "Базовый год",
        state: "Статус",
        term: "Сроки",
        depositState0: "В настоящее время",
        depositState1: "С нетерпением жду",
        label_amount: "Количество",
        place_amount: "Введите сумму",
        estimated_income: "Прогнозируемые поступления",
        available_assets: "Имеющиеся активы",
        cancel: "Отменить",
        all: "Все.",
        min_amount: "Минимальная сумма",
        daily: "Повседневное производство",
        waiting_time: "Время ожидания",
        product_name: "Название продукта",
        buy_total: "Общий объем закупок",
        earnings: "Совокупные поступления",

        myMessage: "Сообщение",
        place_card_back: "Нажмите для загрузки фотографии с обратной стороны удостоверения личности",
        side1: "Управление заказами",
        side2: "Изменить пароль",
        side3: "Язык",
        side4: "Списание",
        product: "Продуктовый центр",
        direction: "Направление",
        cycle: "Период операции",
        long_buy: "Купить много",
        short_sell: "Продажа",
        open_time: "Время открытия",
        close_time: "Время закрытия парка",
        open_price: "Начальная цена",
        close_price: "Цена закрытия",
        number: "Количество",
        profit_loss: "Убытки и убытки",
        commission: "Плата за услуги",
        account_type: "Тип счета",
        type1: "Монеты",
        type2: "Законодательство",
        type3: "Контракты",
        type4: "Параметры",
        type5: "Финансы",
        message1: "Введите сумму",
        message2: "Недостаточный остаток на счете",
        place_transPsd: "Введите пароль транзакции.",
        countpath: "Автоматический переход на страницу заказа после окончания обратного отсчета",
        nowpath: "Прыгай!",
    },
    loan: {
        tester: "Пробный счет",
        rechargePic: "Платежная квитанция",

        walletLink: "Пожалуйста, откройте это соединение в вашем кошельке",
        connectWallet: "Подключить кошелек",

        loan_state_4: "Неудача",
        loan_state_3: "Успех",
        loan_state_2: "Выплачено",
        loan_state_1: "Ожидание",
        repay_state_2: "Платежи",
        repay_state_1: "Ожидание",
        repay_create: "Срок кредитования",
        repay_state: "Страна",
        repay_times: "Этап",
        repay_time: "Время",
        repay_money: "Количество",
        repay_total: "Сумма кредита",
        repay_pay: "Учет платежей",
        repay: "Кредитные записи",
        loan_pic4: "Фотография удостоверения личности",
        loan_pic3: "Банковские счета",
        loan_pic2: "Свидетельство о доходах",
        loan_pic1: "Недвижимость",
        loan_pic: "Кредитный кредит (убедитесь, что картинка хорошо видна)",
        loan_pics: "Удостоверение личности",
        loan_pics_view: "Нажмите для просмотра",
        loan_repay_method_1: "Единовременное обслуживание долга" ,
        loan_repay: "Способы погашения",
        loan_apr: "Апрель",
        loan_time: "Кредитный цикл",
        loan_type_1: "День",
        loan_type_2: "Луна",
        loan_money: "Количество",
        loan: "Кредиты",
    },
    foot: {
        nav1: "Домашняя страница",
        nav2: "Рынок",
        nav3: "Биржа",
        nav4: "Фьючерсы",
        nav5: "Активы",
    },
    account: {
        verifyPass: "Проверить пароль",
        verifySuccess: "Подтвержденные",
        verifyFailed: "Не проверять",
        login: "Регистрация",
        register_email: "Электронная почта",
        register_phone: "Телефон",
        label_phone: "Телефон",
        place_phone: "Пожалуйста, введите свой номер телефона",
        label_eMail: "Электронная почта",
        place_eMail: "Введите адрес электронной почты",
        tip_eMail: "Пожалуйста, введите свой адрес электронной почты правильно.",
        label_password: "Пароль",
        place_password: "Введите пароль.",
        tip_password: "Введите правильный пароль.",
        no_account: "Нет аккаунта?" ,
        register: "Регистрация",
        send: "Отправить код проверки",
        label_code: "Код проверки",
        place_code: "Код проверки",
        tip_code: "Введите код проверки.",
        label_invitation: "Код приглашения",
        place_invitation: "Введите код приглашения.",
    },
    assets: {
        title: "Имущество",
        total_assets: "Увеличение общего объема активов",
        recharge: "Депозит",
        withdraw: "Снятие средств",
        available: "Доступность",
        freeze: "Замораживание",
        total: "Всего",
        label_bank: "Валюта",
        place_bank: "Пожалуйста, выберите валюту.",
        tip_bank: "Пожалуйста, выберите валюту.",
        label_wallet: "Бумажник",
        place_wallet: "Пожалуйста, выберите кошелек",
        tip_wallet: "Пожалуйста, выберите кошелек",
        place_amount: "Введите сумму",
        tip_amount: "Пожалуйста, введите сумму правильно.",
        label_password: "Пароль",
        place_password: "Введите пароль.",
        tip_password: "Введите правильный пароль.",
        label_balance: "Доступные USDT",
        all: "Все.",
        no_password: "Для безопасности ваших средств, пожалуйста, установите",
        withdraw_psd: "Код вывода",
        type1: "Биржа",
        type2: "Фиат.",
        type3: "Фьючерсы",
        type4: "Второй фьючерс",
        type5: "Финансы",
        convert: "Эквивалентность",
        now_currency: "Текущая валюта",
        link_type: "Тип цепи",
        recharge_address: "Адрес депозита",
        copy: "Копировать адрес",
        label_amount: "Сумма вкладов",
        palce_amount: "Введите сумму депозита.",
        label_address: "Адрес кошелька",
        palce_address: "Введите адрес кошелька",
        submit_process: "Представлено",
        tip1: "Пожалуйста, не пополняйте активы, не относящиеся к USDT, по указанному адресу, иначе они не будут возвращены.",
        tip2: "Заполнение токенов USDT поддерживается только простым способом отправки, а использование других токенов в настоящее время недоступно.",
        tip3: "После того, как вы пополняете этот адрес, вам нужно подтверждение всего сетевого узла. После подтверждения сети счет будет зачислен в кредит, а монета может быть извлечена после подтверждения сети",
        tip4: "Ваш адрес пополнения не будет часто меняться, вы можете повторно пополнять; Если что - то изменится, мы постараемся сообщить вам об этом через онлайн - объявление или электронную почту.",
        tip5: "Убедитесь, что ваш компьютер и браузер защищены от изменения или утечки информации.",
        record_title: "Регистрация операций",
        label_number: "Номер",
        label_record: "Запись",
        label_time: "Время",
        label_price: "Цены",
        label_volume: "Громкость",
        transfer_title: "Пересадка",
        from: "От",
        to: "Б",
        transfer_record: "Учет переводов",
        label_withAddress: "Адрес снятия средств",
        place_withAddress: "Пожалуйста, сначала укажите свой адрес.",
        deposit_title: "Добыча под залог",
        buy: "Покупка",
        sell: "Продажа",
        week: "Период",
        daily_dividend: "Ежедневные дивиденды",
        deposit_log: "Залог на добычу",
        userinfo_nav1: "Идентификация",
        userinfo_nav2: "Мои общие ссылки",
        userinfo_nav3: "Мой код разрешения.",
        userinfo_nav4: "Центр безопасности",
        userinfo_nav5: "Метод сбора",
        userinfo_nav6: "Онлайн обслуживание клиентов",
        userinfo_nav7: "О нас",
        userinfo_nav8: "Скачать приложение",
        userinfo_nav9: "Центр помощи",
        logout: "Списание",
        second: "Секундные фьючерсы",
        second_title: "Контракт на скорость",
        invite: "Приглашение",
        legal: "Фиат.",
        ieo: "МЭО",
        state0: "Все.",
        state1: "Предварительная продажа",
        state2: "Подписка",
        state3: "Все кончено.",
        history_record: "История",
        legal_tab1: "Я хочу купить.",
        legal_tab2: "Я хочу продать.",
        transType: "Тип операции",
        order_state: "Статус",
        order_state1: "Неполный",
        order_state2: "Выплаты",
        order_state3: "Задержка подтверждения",
        order_state4: "Защита прав",
        order_state5: "Отменить",
        order_state6: "Полностью",
        reset: "Сбросить",
        hand_number: "Число участников",
        long: "Пошли.",
        short: "Продажа",
        confirm_order: "Подтверждение выдачи заказа",
        type: "Тип",
        multiple_number:"Множество",
        now_entrust: "Нынешняя делегация",
        all_entrust: "Все делегации",
        history_entrust: "Исторический комитет",
        turnover: "Коэффициент изменения численности персонала",
        operate: "Операция",
        delete: "Удалить",
        order_details: "Подробности заказа",
        orderDetails_tab1: "В списке",
        orderDetails_tab2: "Сделки",
        orderDetails_tab3: "Закрыть",
        orderDetails_tab4: "Отменить",
        risk_rate: "Коэффициент риска",
        total_pl: "Совокупные прибыли и убытки от владения акциями",
        closing_position: "Закрыть одним нажатием",
        position_list: "Список должностей",
        order_record: "Запись заказа",
        profit_rate: "Норма прибыли",
        buy_long: "Купи.",
        buy_short: "Купи.",
        trans_mode: "Модель торговли",
        opening_quantity: "Количество открытий",
        opening_number: "Время открытия",
        place_transMount: "Введите лимит транзакции.",
        account_balance: "Сальдо счета",
        privacy_policy: "Политика конфиденциальности",
        contact_us: "Свяжитесь с нами",
        feedback: "Отзывы",
        feedback_type: "Тип обратной связи",
        feedback_type1: "Связанные со снятием средств",
        feedback_type2: "Блокчейн - корреляция",
        label_title: "Заголовок",
        place_title: "Введите заголовок",
        label_cont: "Содержание обратной связи",
        place_cont: "Введите содержимое",
        reset_password: "Сбросить пароль",
        personal_center: "Индивидуальный центр",
        to_authenticate: "Проверка подлинности",
        authenticate_tip1: "·После сертификации лимит снятия может быть увеличен",
        authenticate_tip2: "·Для осуществления операций по снятию средств с операций с законными денежными средствами требуется аутентификация подлинного имени",
        payment_method: "Способы оплаты",
        label_reaName: "Имя",
        palce_reaName: "Имя",
        label_openBank: "Банки",
        palce_openBank: "Введите депозитный банк.",
        label_bankNumber: "Номер банка",
        place_bankNumber: "Пожалуйста, введите номер вашей банковской карты.",
        lable_branchBank: "Банковское отделение",
        place_branchBank: "Введите название филиала.",
        label_IDnumber: "Удостоверения личности",
        place_IDnumber: "Пожалуйста, введите идентификационный номер.",
        label_IDimg: "Фотография ручного удостоверения личности",
        label_IDimg2: "Фотография на обратной стороне удостоверения личности",
        tip_IDimg: "Пожалуйста, загрузите фото с обратной стороны удостоверения.",
        bankCard: "Банковские карточки",
        details: "Детали",
        my_authorization_code: "Мой код разрешения.",
        my_authorization_code_tip: "Мой код разрешения.",
        login_password: "Пароль входа",
        trans_password: "Пароль транзакции",
        modify: "Изменения",
        my_promotional_code: "Мой промокод.",
        promotional_code: "Рекламный код",
        promotional_code_tip: "Сканируйте верхний QR - код или введите промокод, чтобы подтвердить регистрацию.",
        copy_link: "Копировать ссылку",
        modify_trans_psd: "Изменить пароль транзакции",
        label_email_verify: "Проверка электронной почты",
        place_email_verify: "Введите код проверки.",
        send: "Отправить",
        email_verify_tip: "Код подтверждения будет отправлен на вашу электронную почту",
        label_bitTitle: "Да.",
    },
    currency: {
        position: "Должности",
        drawer_title: "Параметры",
        now: "Цены",
        open: "Нормально открыт",
        volume: "Количество",
        height: "Высокий",
        low: "Низкий",
        buy: "Купить / Сделать больше",
        sell: "Продать / Пустить",
        name: "Имя",
        direction: "Направление",
        crm_order: "Подтверждение заказа",
        now_price: "Цены",
        type_time: "Время доставки (на рисунке слева показана более высокая отдача)",
        second: "С",
        buy_amount: "Количество",
        available: "Доступность",
        service_fee: "Расходы",
        min_number: "Минимальная покупка ",
        errorTip1: "Неправильный ввод суммы",
        errorTip2: "Недостаточный остаток средств",
        auto_jump: "Автоматически переходить на заказы.",
        keep: "Продолжайте заказывать",
        revenue: "Ожидаемые поступления",
    },
    home: {
        quick: "Быстрая промывка монет.",
        support: "Поддержка",
        nav1: "Поделиться",
        nav2: "Бумажник",
        nav3: "Информация для прессы",
        nav4: "Учебный план",
        nav5: "Онлайн чат",
        nav6: "Горнодобывающая промышленность",
        nav7: "О нас",
        nav8: "Новые монеты",
        increase: "Лучшие победители",
        volume: "Высшее руководство",
        table_name: "Имя",
        table_lastPrice: "Цены",
        table_increase: "Изменить",
        table_volume: "Круглосуточный объем",
    },
    lockming: {
        profit: "Последнее время (ежедневный доход)",
        cycle: "Финансовый цикл",
        limit: "Единое ограничение",
        dividend_time: "Время выплаты дивидендов",
        every_day: "Ежедневно",
        funds: "Средства, находящиеся под опекой",
        back: "Срок возврата",
        ransom: "Выкуп досрочно.",
        label_amount: "Сумма залога",
        place_amount: "Введите,,,",
        available: "Доступность",
        expect_income: "Ожидаемые поступления",
        title: "Заблокировать добычу",
        tip: "Добыча полезных ископаемых",
        label_today_income: "Прогнозируемая прибыль сегодня",
        label_total_income: "Совокупные поступления",
        label_order: "Заказ хостинга",
        label_number: "Единовременная сумма",
        daily_return: "Ежедневный возврат",
        subscribe: "Подписка",
        position: "Должности",
        label_state: "Статус",
        subscribe_time: "Дополнение time",
        expiration_time: "Срок истечения",
        income: "Поступления",
        errorTip1: "Введите сумму",
        tip_tit: "Вы уверены, что хотите выйти раньше?",
    },
    market: {
        spots: "Места",
        contract: "Фьючерсы",
        currency_trans: "Параметры",
        increase: "Лучшие победители",
        volume: "Высшее руководство",
        label_name: "Имя",
        label_lastPrice: "Цены",
        table_increase: "Изменить",
        table_volume: "Круглосуточный объем",
    },
    my: {
        details: "Детали",
        help: "Центр помощи",
        information: "Справочная служба",
        language: "Переключение языка",
        password: {
            account_title: "Изменить пароль",
            withdraw_title: "Изменить пароль",
            label_oldPsd: "Старый пароль",
            place_oldPsd: "Введите свой старый пароль.",
            label_newPsd: "Новый пароль",
            place_newPsd: "Введите новый пароль.",
            label_crmPsd: "Подтвердить пароль",
            place_crmPsd: "Введите новый пароль.",
            withdraw_tip: "Если вы не установили пароль для снятия денег, оставьте его пустым.",
        },
        address: {
            title: "Адрес кошелька",
            account_number: "Номер счета",
            add_btn: "Добавить адрес",
            label_type: "Выбор типа",
            place_type: "Выберите тип",
            label_number: "Счета",
            place_number: "Введите номер счета.",
        },
        history: {
            title: "История",
            recharge_record: "Регистрация пополнения",
            withdraw_record: "Отчет о снятии средств",
            withdraw: "Снятие средств",
            number: "Количество",
            currency: "Количество",
            state: "Статус",
            state0: "Нерешенные",
            state1: "Полностью",
            state2: "Отказ",
        },
        identity: {
            title: "Сертификация",
            label_country: "Гражданство",
            place_country: "Пожалуйста, выберите гражданство",
            label_name: "Имя",
            place_name: "Введите ваше имя",
            label_id: "Номер удостоверения личности",
            place_id: "Введите номер вашего удостоверения личности / водительского удостоверения / паспорта",
        },
        my: {
            list1: "Сертификация",
            list2: "История заказов",
            list3: "Запись",
            list4: "Адрес кошелька",
            list5: "Переключить язык",
            list6: "Пригласить друзей",
            list7: "Обслуживание клиентов",
            list8: "Учебный план",
            list9: "Изменить пароль",
            logout: "Выход",
        },
        order: {
            title: "История заказов",
            direction: "Направление",
            sell_price: "Цена заказа",
            number: "Количество",
            type: "Время поставки",
            buy_price: "Закупочная цена",
            profit: "Отчет о прибылях и убытках",
            sell_time: "Время продажи",
        },
        share: {
            title: "Пригласить друзей",
            save_ewm: "Сохранить QR - код",
            invite_link: "Ссылка на приглашение",
            copy_address: "Копировать адрес",
            invite_code: "Регистрационный код",
            copy_code: "Копировать содержимое",
            tip: "Регистрация по QR - коду или ссылке выше",
        },
    },
    transaction: {
        spots: "Наличные товары",
        contract: "Фьючерсы",
        currency_trans: "Параметры",
        exchange: "Обмен",
        sustainable: "Вечный",
        warehouse: "Изоляция",
        jump_lever: "Регулировочный рычаг",
        sell: "Продажа",
        buy: "Покупка",
        available: "Доступность",
        check_price: "Ограничения",
        market_price: "Рынок",
        number: "Количество",
        max: "Макс.",
        bail: "Стоимость",
        buy_long: "Купить / Сделать больше",
        sell_short: "Продать / Пустить ",
        last_price: "Цены",
        now_entrust: "Непогашенные заказы",
        now_position: "Должности",
        table_amount: "Количество",
        is_reduce: "Только сокращение",
        shi: "Вот именно.",
        fou: "Ошибка",
        position_number: "Размер",
        is_profit: "Не реализован PNL",
        wei: "Пока нет.",
        open_price: "Входные цены",
        mark_price: "Отметить цену",
        flat_price: "Ликвидация",
        trim_lever: "Регулировочный рычаг",
        flat_store: "Закрыть позицию",
        trim_bail: "Настройка границ",
        reduce_bail: "Сокращение Аргин",
        now_bail: "Гарантия текущей позиции",
        max_add: "Максимальное увеличение",
        refer_flat: "Скорректированный базовый сильный паритет",
        expect_profit: "Ожидаемые прибыли и убытки"
    },
    common: {
        confirm: "Подтверждение",
        Cancel: "Отменить",
        all: "Все.",
        close: "Отменить",
        copy_success: "Репликация удалась!" ,
        copy_error: "Не удалось скопировать!" ,
        place_input: "Введите содержимое",
        more: "Больше.",
        main: "Майн",
        sub: "Субп",
        success: "Успех!",
        cancel_tip: "Вы уверены, что хотите отменить?" ,
        notice: "Уведомление",
        logout_tip: "Вы уверены, что хотите уйти?",
        tip: "Советы",
        day: "День",
        empty: "Данные отсутствуют"
    },
    message: {
        cancel_order: "Отмена доверенности?",
        stop_out: "Принудительное закрытие позиций?",
    },
    params: {
        transType0: "Наличные товары",
        transType1: "Контракты",
        directionType0: "Покупка",
        directionType1: "Продажа",
        lockStateType0: "Операция",
        lockStateType1: "Все кончено.",
        lockStateType2: "Отменить",
    },
    city: {
        albania: "Албания",
        algeria: "Алжир",
        argentina: "Аргентина",
        armenia: "Армения",
        australia: "Австралия",
        pakistan: "Пакистан",
        austria: "Австрия",
        bahrain: "Бахрейн",
        belgium: "Бельгия",
        bosnia_and_Herzegovina: "Босния и Герцеговина",
        brazil: "Бразилия",
        brunei: "Бруней",
        bulgaria: "Болгарияworld. kgm",
        cambodia: "Камбоджа",
        canada: "Канада",
        cameroon: "Камерун",
        chile: "Чили",
        colombia: "Колумбия",
        costa_Rica: "Коста - Рика",
        croatia: "Хорватия",
        cyprus: "Кипр",
        czech_Republic: "ЧешскаяРеспублика",
        denmark: "Дания",
        dominican_Republic: "Доминиканская Республика",
        egypt: "Египет",
        estonia: "Эстония",
        ethiopia: "Эфиопияworld. kgm",
        finland: "Финляндия",
        france: "Франция",
        georgia: "Джорджия",
        germany: "Германия",
        ghana: "Гана",
        greece: "Греция",
        guyana: "Гайана",
        honduras: "Гондурас",
        hong_Kong_China: "Гонконг, Китай",
        hungary: "Венгрияworld. kgm",
        iceland: "Исландияworld. kgm",
        ireland: "Ирландия",
        italy: "Италия",
        india: "Индия",
        indonesia: "Индонезияworld. kgm",
        israel: "Израиль",
        iran: "Иран",
        iraq: "Ирак",
        japan: "Япония",
        kazakstan: "Казахстан",
        kenya: "Кенияworld. kgm",
        korea: "Корея",
        kuwait: "Кувейт",
        kyrgyzstan: "Кыргызстан",
        laos: "Лаос",
        latvia: "Латвияworld. kgm",
        lithuania: "Литва",
        luxembourg: "Люксембург",
        macao_China: "Макао Китай",
        macedonia: "Македония",
        malaysia: "Малайзия",
        malta: "Мальта",
        mexico: "Мексика",
        moldova: "Республика Молдова",
        monaco: "Монако",
        mongolia: "Монголия",
        montenegro: "Черногорияworld. kgm",
        morocco: "Марокко",
        myanmar: "Мьянма",
        netherlands: "Нидерланды",
        new_Zealand: " НоваяЗеландия",
        nepal: "Непал",
        nigeria: "Нигерияworld. kgm",
        norway: "Норвежский язык",
        oman: "Оман",
        palestine: "Палестина",
        panama: "Панамаworld. kgm",
        paraguay: "Парагвай",
        peru: "Перу",
        philippines: "Филиппины",
        poland: "Польша",
        portugal: "Португалия",
        puerto_Rico: "Пуэрто - Рико",
        qatar: "Катар",
        romania: "Румыния",
        russia: "Россия",
        republic_of_Trinidad_and_Tobago: "Республика Тринидад и Тобаго",
        rwanda: "Руанда",
        saudi_Arabia: "Саудовская Аравия",
        serbia: "Сербия",
        singapore: "Сингапур",
        slovakia: "Словакия",
        slovenia: "Словенияworld. kgm",
        south_Africa: "ЮжнаяАфрика",
        spain: "Испания",
        sri_Lanka: "Шри - Ланка",
        sweden: "Швеция",
        switzerland: "Швейцария",
        taiwan_China: "Тайвань Китай",
        tajikistan: "Таджикистан",
        tanzania: "Танзанияworld. kgm",
        thailand: "Таиланд",
        turkey: "Индейка.",
        turkmenistan: "Туркменистан",
        ukraine: "Украина",
        united_Arab_Emirates: "Объединенные Арабские Эмираты",
        united_Kingdom: "Соединенное Королевство Великобритании и Северной Ирландии",
        united_States: " США",
        uzbekistan: "Узбекистан",
        venezuela: "Венесуэлаworld. kgm",
        vietnam: "Вьетнам",
        afghanistan: "Афганистан",
        angola: "Анголаworld. kgm",
        azerbaijan: "Азербайджан",
        bangladesh: "Бангладеш",
        belarus: "Беларусь",
        belize: "Белиз",
        benin: "Бенин",
        bhutan: "Бутан",
        bolivia: "Боливияworld. kgm",
        botswana: "Ботсвана",
        british_Virgin_Islands: "Британские Виргинские острова",
        burkina_Faso: "Буркина - Фасо",
        burundi: "Бурунди",
        cape_Verde: "Кабо - Верде",
        cayman_Islands: "Каймановы острова",
        central_African_Republic: "Центральноафриканская Республика",
        chad: "Чад",
        comoros: "КоморскиеОстрова",
        the_Republic_of_the_Congo: " Республика Конго",
        democratic_Republic_of_the_Congo: "Демократическое Конго",
        djibouti: "Джибути",
        ecuador: "Эквадор",
        el_Salvador: "Сальвадор",
        equatorial_Guinea: "Экваториальная Гвинея",
        eritrea: "Эритрея",
        fiji: "Фиджи",
        gabon: "Габон",
        gambia: "Гамбия",
        greenland: "Гренландия",
        guatemala: "Гватемалаworld. kgm",
        guinea: "Гвинея",
        haiti: "Гаити",
        isle_of_Man: " Человеческий остров",
        cote_d_Ivoire: "Коте - д Воир",
        jamaica: "Ямайка",
        jordan: "Джордан!",
        lebanon: "Ливан",
        lesotho: "Лесото",
        liberia: "Либерия",
        libya: "Ливийская Арабская Джамахирия",
        madagascar: "Мадагаскар",
        malawi: "Малави",
        maldives: "МальдивскиеОстрова",
        mali: "Мали",
        mauritania: "Мавритания",
        mauritius: "Маврикий",
        mozambique: "Мозамбик",
        namibia: "Намибия",
        nicaragua: "Никарагуа",
        republic_of_Niger: "За Республику Нигер",
        north_Korea: " Северная Корея",
        reunion: "Воссоединение",
        san_Marino: "Сан - Мариноworld. kgm",
        senegal: "Сенегал",
        sierra_Leone: "Сьерра - Леоне",
        somalia: "Сомали",
        sudan: "Судан",
        suriname: "Суринам",
        eswatini: "Свазиленд",
        syria: "Сирия",
        togo: "Того",
        tonga: "Тонга",
        tunisia: "Тунис",
        united_States_Virgin_Islands: "Виргинские острова Соединенных Штатов",
        uganda: "Угандаworld. kgm",
        uruguay: "Уругвай",
        vatican: "Ватикан",
        yemen: "Йемен",
        yugoslavia: "Югославия",
        zambia: "Замбияworld. kgm",
        zimbabwe: "Зимбабве",
        china: "Китай",
    }
};
