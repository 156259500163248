<template>
  <router-view></router-view>
</template>
<script>
export default {
  mounted() {
    if (!this._isMobile()) {
      window.location.href = "https://www.txfun.com/";
      console.log("手机端");
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>
<style lang="less">
body {
  background-color: var(--bg-app1) !important;

  --color-theme-green: #2dcea2;
  --color-theme-blue: #6689ff;
  --color-theme-red: #f05b5b;
  --color-theme-grey: #999999;
  --color-theme-main: #FFFFFF;
  --color-theme-orange: #f05b5b;
  --bg-theme-green: #2dcea2;
  --bg-theme-red: #f05b5b;
  --bg-theme-blue: #6689ff;
  --bg-theme-grey: rgba(153, 153, 153, 0.1);
  --bg-img-card: url(/static/image/card-bg.png);
  --bg-card-green: #f1fffa;

  --bg-app1: #121420;
  --bg-app2: #1b1d2c;
  --bg-app3: #2c3546;
  --border-color1: rgba(153, 153, 153, 0.1);
  --bg-drawer: #131f30;

  .app {
    min-height: 100vh;
    &.light {
      background: #f7f7fb !important;
    }
  }
  .el-divider {
    background-color: var(--border-color1);
    background-color: #2C3546;
  }
  .el-input__inner {
    background-color: var(--bg-app2);
    border-color: var(--border-color1);
  }
  .el-input.is-disabled .el-input__inner {
    background-color: var(--bg-app2);
    border-color: var(--border-color1);
  }
  .el-input-group__append, .el-input-group__prepend {
    background-color: var(--bg-app3);
    border-color: var(--border-color1);

  }
  .el-select-dropdown {
    .el-select-dropdown__item {
      font-size: 24px !important;
      height: 60px;
      line-height: 60px;
    }

    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      color: var(--color-theme-green) !important;
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
  }

  .el-form-item {
    margin-bottom: 40px;
  }

  .el-button {
    background-color: var(--bg-theme-green);
    height: 80px;
    color: #fff;
    width: 100%;
    border-radius: 15px;
    font-size: 28px;
    border: none;
  }

  .el-input__inner {
    font-size: 28px;
    color: #FFF;
  }

  .cell {
    font-size: 24px;
  }

  .el-message-box {
    width: 80% !important;
    padding: 20px;
    .el-message-box__header {
      padding: 30px;
      .el-icon-close {
        font-size: 28px;
      }
    }
    .el-message-box__title {
      font-size: 36px;
    }
    .el-message-box__content {
      padding: 30px;
      font-size: 28px;
    }
    .el-message-box__btns {
      .el-button {
        width: auto;
        padding: 0 40px;
      }
    }
    .el-message-box__message {
    }
  }

  .el-table {
    color: #fff;
    background: var(--bg-app2);
    tr {
      background: var(--bg-app2);
    }
    th.el-table__cell {
      background: var(--bg-app2);
    }

    td.el-table__cell,
    th.el-table__cell.is-leaf {
      // border-bottom: 1 solid #EBEEF5;
      border-color: var(--color-theme-grey);
      border-color: rgba(153, 153, 153, 0.1);
    }

    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: rgba(153, 153, 153, 0.1);
    }
    .rate {
      overflow: hidden;
    }
  }

  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
    background-color: rgba(153, 153, 153, 0.1) !important;
  }
}
.el-dropdown-menu {
  .el-dropdown-menu__item {
    padding: 20px;
    font-size: 14px;
  }
}

.el-drawer {
  background-color: var(--bg-drawer) !important;
}
</style>
