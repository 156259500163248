import request from "@/utils/request";

export function login2(data) {
    return request({
        url: "/api/v1/auth/signin2",
        method: "post",
        data,
    });
}

export function login(data) {
    return request({
        url: "/api/v1/auth/signin",
        method: "post",
        data,
    });
}

export function register(data) {
    return request({
        url: "/api/v1/auth/signup",
        method: "post",
        data,
    });
}

export function forget(data) {
    return request({
        url: "/api/v1/auth/forget-pass",
        method: "post",
        data,
    });
}

export function forgetPay(data) {
    return request({
        url: "/api/v1/auth/forget-pay-pass",
        method: "post",
        data,
    });
}

export function registerCode(data) {
    return request({
        url: "/api/v1/auth/signup-captcha",
        method: "post",
        data,
    });
}

export function forgotCode(data) {
    return request({
        url: "/api/v1/auth/forget-captcha",
        method: "post",
        data,
    });
}

export function getInfo() {
    return request({
        url: "/api/v1/profile/profile",
        method: "get",
    });
}

export function getInvite() {
    return request({
        url: "/api/v1/profile/invite",
        method: "get",
    });
}


export function getIdentity() {
    return request({
        url: "/api/v1/profile/identity",
        method: "get",
    });
}

export function createIdentity(data) {
    return request({
        url: "/api/v1/profile/identity/create",
        method: "post",
        data,
    });
}

export function updateIdentity(data) {
    return request({
        url: "/api/v1/profile/identity/update",
        method: "post",
        data,
    });
}

export function changePass(data) {
    return request({
        url: "/api/v1/profile/profile/change",
        method: "post",
        data,
    });
}

export function changePay(data) {
    return request({
        url: "/api/v1/profile/profile/pay",
        method: "post",
        data,
    });
}

export function changeEmail(data) {
    return request({
        url: "/api/v1/profile/profile/email",
        method: "post",
        data,
    });
}

export function changePhone(data) {
    return request({
        url: "/api/v1/profile/profile/phone",
        method: "post",
        data,
    });
}

export function registerCode2(data) {
    return request({
        url: "/api/v1/auth/validate-captcha",
        method: "post",
        data,
    });
}

export function logout() {
    return request({
        url: "/api/v1/auth/logout",
        method: "post",
    });
}

export function getMessageList(query) {
    return request({
        url: "/api/v1/profile/message",
        method: "get",
        params: query,
    });
}

export function getMessageDetail(id) {
    return request({
        url: "/api/v1/profile/message/" + id,
        method: "get",
    });
}
