export default {
    addNew: {
        my_wallet: "我的钱包",
        add_wallet: "添加钱包",
        place_wallet_type: "请选择钱包类型",
        place_wallet_name: "请输入钱包名称",
        place_wallet_address: "请输入钱包地址",
        reference_annualization: "參攷年化",
        state: "狀態",
        term: "期限",
        depositState0: "火熱進行中",
        depositState1: "敬請期待",
        label_amount: "金額",
        place_amount: "請輸入金額",
        estimated_income: "預計收入",
        available_assets: "可用資產",
        cancel: "取消",
        all: "全部",
        min_amount: "最小金額",
        daily: "日產量",
        waiting_time: "等待時間",
        product_name: "產品名稱",
        buy_total: "購買總數量",
        earnings: "累計收益",

        myMessage: "我的信息",
        place_card_back: "點擊上傳身份證反面照",
        side1: "訂單管理",
        side2: "修改密碼",
        side3: "語言",
        side4: "登出",
        product: "產品",
        direction: "方向",
        cycle: "交易期間",
        long_buy: "做多買入",
        short_sell: "做空賣出",
        open_time: "開倉時間",
        close_time: "平倉時間",
        open_price: "開倉價格",
        close_price: "平倉價格",
        number: "數量",
        profit_loss: "盈虧",
        commission: "手續費",
        account_type: "賬變類型",
        type1: "幣幣帳戶",
        type2: "法幣帳戶",
        type3: "合約帳戶",
        type4: "期權帳戶",
        type5: "理財帳戶",
        message1: "請輸入金額",
        message2: "帳戶餘額不足",
        place_transPsd: "請輸入交易密碼",
        countpath: "倒數計時結束自動跳轉到訂單頁面",
        nowpath: "立即跳轉",
    },
    loan: {
        tester: "体验账号",
        rechargePic: "转账凭证",

        walletLink: "請在錢包中打開此連接！",
        connectWallet: "連接錢包",

        loan_state_4: "失敗",
        loan_state_3: "已還款",
        loan_state_2: "已放款",
        loan_state_1: "待稽核",
        repay_state_2: "已還款",
        repay_state_1: "未還款",
        repay_create: "貸款時間",
        repay_state: "狀態",
        repay_times: "期數",
        repay_time: "還款時間",
        repay_money: "還款總額",
        repay_total: "貸款金額",
        repay_pay: "還款記錄",
        repay: "貸款記錄",
        loan_pic4: "身份證照片",
        loan_pic3: "銀行帳單",
        loan_pic2: "收入證明",
        loan_pic1: "房產證明",
        loan_pic: "信用貸款（請確保圖片清晰可見）",
        loan_pics: "證明材料",
        loan_pics_view: "點擊查看",
        loan_repay_method_1: "一次性還本息",
        loan_repay: "還款方式",
        loan_apr: "日利率",
        loan_time: "貸款週期",
        loan_type_1: "天",
        loan_type_2: "月",
        loan_money: "貸款金額",
        loan: "助力貸款",
    },
    foot: {
        nav1: "主页",
        nav2: "行情",
        nav3: "币币",
        nav4: "合约",
        nav5: "资产",
    },
    account: {
        verifyPass: "验证密码",
        verifySuccess: "已认证",
        verifyFailed: "未认证",
        login: "登入",
        register_email: "邮箱注册",
        register_phone: "手机注册",
        label_phone: "手机号",
        place_phone: "请输入您手机号",
        label_eMail: "邮箱",
        place_eMail: "请输入您的电子邮箱",
        tip_eMail: "请正确输入您的电子邮箱",
        label_password: "密码",
        place_password: "请输入密码",
        tip_password: "请输入正确的密码",
        no_account: "还没有账号？",
        register: "注册",
        send: "发送验证码",
        label_code: "验证码",
        place_code: "验证码",
        tip_code: "请输入验证码",
        label_invitation: "邀请码",
        place_invitation: "请输入邀请码",
    },
    assets: {
        title: "资产",
        total_assets: "总资产折合",
        recharge: "充币",
        withdraw: "提币",
        available: "可用",
        freeze: "冻结",
        total: "总计",
        label_bank: "币种",
        place_bank: "请选择币种",
        tip_bank: "请选择币种",
        label_wallet: "钱包",
        place_wallet: "请选择钱包",
        tip_wallet: "请选择钱包",
        label_amount: "金额",
        place_amount: "请输入金额",
        tip_amount: "请正确输入金额",
        label_password: "密码",
        place_password: "请输入密码",
        tip_password: "请输入正确的密码",
        label_balance: "可用USDT",
        all: "全部提现",
        no_password: "为了您的资金安全，请设置",
        withdraw_psd: "提现密码",
        type1: "币币账户",
        type2: "法币账户",
        type3: "合约账户",
        type4: "极速合约账户",
        type5: "理财账户",
        convert: "折合",
        now_currency: "当前币种",
        link_type: "链类型",
        recharge_address: "充币地址",
        copy: "复制地址",
        palce_amount: "请输入充值数量",
        label_address: "钱包地址",
        palce_address: "请输入钱包地址",
        submit_process: "提交审核",
        tip1: "请勿向上述地址充值任何非USDT资产，否则资产将不可找回。",
        tip2: "USDT充币仅支持simple send的方法，使用其他方法的充币暂时无法上账，请您谅解。",
        tip3: "您充值至上述地址后，需要整个网络节点的确认，1次网络确认后到账，6次网络确认可提币。",
        tip4: "您的充值地址不会经常改变，可以重复充值;如有更改，我们会尽量通过网络公告或邮件通知您。",
        tip5: "请务必确认电脑及浏览器安全，防止信息被篡改或泄露。",
        record_title: "交易记录",
        label_number: "数量",
        label_record: "记录",
        label_time: "时间",
        label_price: "价格",
        label_volume: "量",
        transfer_title: "划转",
        from: "从",
        to: "至",
        transfer_record: "划转记录",
        label_withAddress: "提币地址",
        place_withAddress: "请先设置您的提币地址",
        deposit_title: "质押挖矿",
        buy: "购买",
        sell: "出售",
        week: "周期",
        daily_dividend: "日分红",
        deposit_log: "质押挖矿记录",
        userinfo_nav1: "身份认证",
        userinfo_nav2: "我的分享链接",
        userinfo_nav3: "我的授权码",
        userinfo_nav4: "安全中心",
        userinfo_nav5: "收款方式",
        userinfo_nav6: "在线客服",
        userinfo_nav7: "关于我们",
        userinfo_nav8: "下载APP",
        userinfo_nav9: "帮助中心",
        logout: "退出登录",
        second: "秒合约",
        second_title: "极速合约",
        invite: "邀请",
        legal: "法币交易",
        ieo: "IEO",
        state0: "全部",
        state1: "预售中",
        state2: "认购中",
        state3: "已结束",
        history_record: "历史记录",
        legal_tab1: "我要买",
        legal_tab2: "我要卖",
        transType: "交易类型",
        order_state: "订单状态",
        order_state1: "未完成",
        order_state2: "已付款",
        order_state3: "延期确认",
        order_state4: "维权中",
        order_state5: "已取消",
        order_state6: "已完成",
        reset: "重置",
        hand_number: "交易手数",
        long: "做多",
        short: "做空",
        confirm_order: "确认下单",
        type: "类型",
        multiple_number: "倍数",
        now_entrust: "当前委托",
        all_entrust: "全部委托",
        history_entrust: "历史委托",
        turnover: "交易额",
        operate: "操作",
        delete: "删除",
        order_details: "订单详情",
        orderDetails_tab1: "挂单中",
        orderDetails_tab2: "交易中",
        orderDetails_tab3: "已平仓",
        orderDetails_tab4: "已撤单",
        risk_rate: "风险率",
        total_pl: "持仓总盈亏",
        closing_position: "一键平仓",
        position_list: "持仓列表",
        order_record: "订单记录",
        profit_rate: "盈利率",
        buy_long: "买涨",
        buy_short: "买跌",
        trans_mode: "交易模式",
        opening_quantity: "开仓数量",
        opening_number: "开仓时间",
        place_transMount: "请输入交易额度",
        account_balance: "账户余额",
        privacy_policy: "隐私政策",
        contact_us: "联系我们",
        feedback: "用户反馈",
        feedback_type: "反馈类型",
        feedback_type1: "提款相关",
        feedback_type2: "区块链相关",
        label_title: "标题",
        place_title: "请输入标题",
        label_cont: "反馈内容",
        place_cont: "请输入内容",
        reset_password: "重置密码",
        personal_center: "个人中心",
        to_authenticate: "去认证",
        authenticate_tip1: "·认证后可提升提币额度",
        authenticate_tip2: "·需要通过实名认证，才可进行法币交易提现操作",
        payment_method: "收款方式",
        label_reaName: "真实姓名",
        palce_reaName: "真实姓名",
        label_openBank: "开户行",
        palce_openBank: "请输入开户行",
        label_bankNumber: "银行卡号",
        place_bankNumber: "请输入银行卡号",
        lable_branchBank: "开户支行",
        place_branchBank: "请输入开户支行名称",
        label_IDnumber: "身份证号",
        place_IDnumber: "请输入身份证号",
        label_IDimg: "手持身份证照片",
        label_IDimg2: "背面身份证照片",
        tip_IDimg: "请上传身份证背面照片",
        bankCard: "银行卡",
        details: "详情",
        my_authorization_code: "我的授权码",
        my_authorization_code_tip: "我的授权码",
        login_password: "登录密码",
        trans_password: "交易密码",
        modify: "修改",
        my_promotional_code: "我的推广码",
        promotional_code: "推广码",
        promotional_code_tip: "扫上面的二维码图案或输入推广码，确认注册",
        copy_link: "复制邀请链接",
        modify_trans_psd: "修改交易密码",
        label_email_verify: "邮箱验证",
        place_email_verify: "请输入验证码",
        send: "发送",
        email_verify_tip: "验证码将发送到您的邮箱",
        label_bitTitle: "交易对",
        label_profit: "盈利",
    },
    currency: {
        position: "持仓",
        drawer_title: "货币交易",
        now: "当前",
        open: "开盘",
        volume: "成交量",
        height: "最高",
        low: "最低",
        buy: "买多",
        sell: "卖空",
        name: "名字",
        direction: "方向",
        crm_order: "确认订单",
        now_price: "现价",
        type_time: "交割时间(左滑收益更高)",
        second: "秒",
        buy_amount: "买入金额",
        available: "可用",
        service_fee: "手续费",
        min_number: "最少",
        errorTip1: "金额输入有误",
        errorTip2: "可用余额不足",
        auto_jump: "后自动跳转订单",
        keep: "继续下单",
        revenue: "预期收益",
    },
    home: {
        quick: "快速冲币",
        support: "支持",
        nav1: "分享",
        nav2: "钱包",
        nav3: "资讯",
        nav4: "教程",
        nav5: "客服",
        nav6: "挖矿",
        nav7: "关于我们",
        nav8: "新币申购",
        increase: "涨幅榜",
        volume: "成交量",
        table_name: "名称",
        table_lastPrice: "最新价",
        table_increase: "涨跌幅",
        table_volume: "成交额",
    },
    lockming: {
        profit: "近日(日收益)",
        cycle: "理财周期",
        limit: "单笔限额",
        dividend_time: "派息时间",
        every_day: "每日",
        funds: "托管资金",
        back: "到期返回",
        ransom: "提前赎回",
        label_amount: "质押金额",
        place_amount: "请输入...",
        available: "可用",
        expect_income: "预计收益",
        title: "锁仓挖矿",
        tip: "挖矿赚不停",
        label_today_income: "预计今日收益",
        label_total_income: "累计收益",
        label_order: "托管的订单",
        label_number: "单笔数额",
        daily_return: "日收益率",
        subscribe: "申购",
        position: "持仓",
        label_state: "状态",
        subscribe_time: "申购时间",
        expiration_time: "到期时间",
        income: "收益",
        errorTip1: "请输入金额",
        tip_tit: "确定要提前退出吗",
    },
    market: {
        spots: "现货",
        contract: "合约",
        currency_trans: "货币交易",
        increase: "涨幅榜",
        volume: "成交量",
        label_name: "名称",
        label_lastPrice: "最新价",
        table_increase: "涨跌幅",
        table_volume: "成交额",
    },
    my: {
        details: "详情",
        help: "帮助中心",
        information: "资讯",
        language: "语言切换",
        password: {
            account_title: "修改密码",
            withdraw_title: "修改密码",
            label_oldPsd: "旧密码",
            place_oldPsd: "请输入旧密码",
            label_newPsd: "新密码",
            place_newPsd: "请输入新密码",
            label_crmPsd: "确认密码",
            place_crmPsd: "请再次输入新密码",
            withdraw_tip: "如未设置过提现密码，请留空",
        },
        address: {
            title: "钱包地址",
            account_number: "账户号码",
            add_btn: "添加地址",
            label_type: "选择类型",
            place_type: "请选择类型",
            label_number: "账户号码",
            place_number: "请输入账户号码",
        },
        history: {
            title: "历史记录",
            recharge_record: "充值记录",
            withdraw_record: "提现记录",
            recharge: "充值",
            withdraw: "提现",
            number: "数量",
            currency: "货币",
            state: "状态",
            state0: "待审核",
            state1: "已完成",
            state2: "已拒绝",
        },
        identity: {
            title: "认证",
            label_country: "选择国籍",
            place_country: "请选择国籍",
            label_name: "姓名",
            place_name: "请输入您的姓名",
            label_id: "证件号",
            place_id: "请输入您的身份证/驾驶证/护照号",
        },
        my: {
            list1: "认证",
            list2: "订单管理",
            list3: "资金记录",
            list4: "钱包地址",
            list5: "切换语言",
            list6: "邀请好友",
            list7: "客服",
            list8: "教程",
            list9: "修改密码",
            logout: "退出登录",
        },
        order: {
            title: "持仓",
            direction: "方向",
            sell_price: "下单价",
            number: "数量",
            type: "交割时间",
            buy_price: "买入价",
            profit: "盈亏",
            sell_time: "卖出时间",
        },
        share: {
            title: "邀请好友",
            save_ewm: "保存二维码",
            invite_link: "邀请链接",
            copy_address: "复制地址",
            invite_code: "注册码",
            copy_code: "复制内容",
            tip: "通过以上二维码或者链接进行注册",
        },
    },
    transaction: {
        spots: "现货",
        contract: "合约",
        currency_trans: "货币交易",
        exchange: "闪兑",
        sustainable: "永续",
        warehouse: "逐仓",
        jump_lever: "跳转杠杆",
        sell: "卖出",
        buy: "买入",
        available: "可用",
        check_price: "限价",
        market_price: "市价",
        number: "数量",
        max: "最大",
        bail: "保证金",
        buy_long: "买多",
        sell_short: "卖空",
        last_price: "最新价",
        now_entrust: "历史持仓",
        now_position: "当前持仓",
        table_amount: "提现金额",
        is_reduce: "只减仓",
        shi: "是",
        fou: "否",
        position_number: "持仓数量",
        is_profit: "实现盈利",
        wei: "未",
        open_price: "开仓价格",
        mark_price: "标记价格",
        flat_price: "强平价格",
        trim_lever: "调整杠杆",
        flat_store: "平仓",
        trim_bail: "调整保证金",
        reduce_bail: "减少保证金",
        now_bail: "当前仓位保证金",
        max_add: "最多可增加",
        refer_flat: "调整后参考强平价",
        expect_profit: "预计盈亏",
    },
    common: {
        confirm: "确认",
        Cancel: "取消",
        all: "全部",
        close: "取消",
        copy_success: "复制成功！",
        copy_error: "复制失败！",
        place_input: "请输入内容",
        more: "更多",
        main: "主图",
        sub: "副图",
        success: "成功！",
        cancel_tip: "你确定要取消吗?",
        notice: "通知",
        logout_tip: "确认退出？",
        tip: "提示",
        day: "天",
        place_inner: "请输入内容",
        fail_banner: "余额不足",
        empty: "暂无数据",
    },
    message: {
        cancel_order: "是否撤销委托?",
        stop_out: "是否强制平仓?",
    },
    params: {
        transType0: "币币",
        transType1: "合约",
        directionType0: "买入",
        directionType1: "卖出",
        lockStateType0: "运行中",
        lockStateType1: "已结束",
        lockStateType2: "已取消",
    },
    city: {
        albania: "阿尔巴尼亚",
        algeria: "阿尔及利亚",
        argentina: "阿根廷",
        armenia: "亚美尼亚",
        australia: "澳大利亚",
        pakistan: "巴基斯坦",
        austria: "奥地利",
        bahrain: "巴林",
        belgium: "比利时",
        bosnia_and_Herzegovina: "波黑",
        brazil: "巴西",
        brunei: "文莱",
        bulgaria: "保加利亚",
        cambodia: "柬埔寨",
        canada: "加拿大",
        cameroon: "喀麦隆",
        chile: "智利",
        colombia: "哥伦比亚",
        costa_Rica: "哥斯达黎加",
        croatia: "克罗地亚",
        cyprus: "塞浦路斯",
        czech_Republic: "捷克",
        denmark: "丹麦",
        dominican_Republic: "多米尼亚共和国",
        egypt: "埃及",
        estonia: "爱沙尼亚",
        ethiopia: "埃塞俄比亚",
        finland: "芬兰",
        france: "法国",
        georgia: "格鲁吉亚",
        germany: "德国",
        ghana: "加纳",
        greece: "希腊",
        guyana: "圭亚那",
        honduras: "洪都拉斯",
        hong_Kong_China: "中国香港",
        hungary: "匈牙利",
        iceland: "冰岛",
        ireland: "爱尔兰",
        italy: "意大利",
        india: "印度",
        indonesia: "印度尼西亚",
        israel: "以色列",
        iran: "伊朗",
        iraq: "伊拉克",
        japan: "日本",
        kazakstan: "哈萨克斯坦",
        kenya: "肯尼亚",
        korea: "韩国",
        kuwait: "科威特",
        kyrgyzstan: "吉尔吉斯斯坦",
        laos: "老挝",
        latvia: "拉脱维亚",
        lithuania: "立陶宛",
        luxembourg: "卢森堡",
        macao_China: "中国澳门",
        macedonia: "马其顿",
        malaysia: "马来西亚",
        malta: "马耳他",
        mexico: "墨西哥",
        moldova: "摩尔多瓦",
        monaco: "摩纳哥",
        mongolia: "蒙古",
        montenegro: "黑山",
        morocco: "摩洛哥",
        myanmar: "缅甸",
        netherlands: "荷兰",
        new_Zealand: "新西兰",
        nepal: "尼泊尔",
        nigeria: "尼日利亚",
        norway: "挪威",
        oman: "阿曼",
        palestine: "巴勒斯坦",
        panama: "巴拿马",
        paraguay: "巴拉圭",
        peru: "秘鲁",
        philippines: "菲律宾",
        poland: "波兰",
        portugal: "葡萄牙",
        puerto_Rico: "波多黎各",
        qatar: "卡塔尔",
        romania: "罗马尼亚",
        russia: "俄罗斯",
        republic_of_Trinidad_and_Tobago: "特立尼达和多巴哥",
        rwanda: "卢旺达",
        saudi_Arabia: "沙特阿拉伯",
        serbia: "塞尔维亚",
        singapore: "新加坡",
        slovakia: "斯洛伐克",
        slovenia: "斯洛文尼亚",
        south_Africa: "南非",
        spain: "西班牙",
        sri_Lanka: "斯里兰卡",
        sweden: "瑞典",
        switzerland: "瑞士",
        taiwan_China: "中国台湾",
        tajikistan: "塔吉克斯坦",
        tanzania: "坦桑尼亚",
        thailand: "泰国",
        turkey: "土耳其",
        turkmenistan: "土库曼斯坦",
        ukraine: "乌克兰",
        united_Arab_Emirates: "阿联酋",
        united_Kingdom: "英国",
        united_States: "美国",
        uzbekistan: "乌兹别克斯坦",
        venezuela: "委内瑞拉",
        vietnam: "越南",
        afghanistan: "阿富汗",
        angola: "安哥拉",
        azerbaijan: "阿塞拜疆",
        bangladesh: "孟加拉国",
        belarus: "白俄罗斯",
        belize: "伯利兹",
        benin: "贝宁",
        bhutan: "不丹",
        bolivia: "玻利维亚",
        botswana: "博茨瓦纳",
        british_Virgin_Islands: "英属维京群岛",
        burkina_Faso: "布基纳法索",
        burundi: "布隆迪",
        cape_Verde: "佛得角",
        cayman_Islands: "开曼群岛",
        central_African_Republic: "中非共和国",
        chad: "乍得",
        comoros: "科摩罗",
        the_Republic_of_the_Congo: "刚果（布）",
        democratic_Republic_of_the_Congo: "刚果（金）",
        djibouti: "吉布提",
        ecuador: "厄瓜多尔",
        el_Salvador: "萨尔瓦多",
        equatorial_Guinea: "赤道几内亚",
        eritrea: "厄立特里亚",
        fiji: "斐济",
        gabon: "加蓬",
        gambia: "冈比亚",
        greenland: "格陵兰",
        guatemala: "危地马拉",
        guinea: "几内亚",
        haiti: "海地",
        isle_of_Man: "马恩岛",
        cote_d_Ivoire: "科特迪瓦",
        jamaica: "牙买加",
        jordan: "约旦",
        lebanon: "黎巴嫩",
        lesotho: "莱索托",
        liberia: "利比里亚",
        libya: "利比亚",
        madagascar: "马达加斯加",
        malawi: "马拉维",
        maldives: "马尔代夫",
        mali: "马里",
        mauritania: "毛里塔尼亚",
        mauritius: "毛里求斯",
        mozambique: "莫桑比克",
        namibia: "纳米比亚",
        nicaragua: "尼加拉瓜",
        republic_of_Niger: "尼日尔",
        north_Korea: "朝鲜",
        reunion: "留尼汪",
        san_Marino: "圣马力诺",
        senegal: "塞内加尔",
        sierra_Leone: "塞拉利昂",
        somalia: "索马里",
        sudan: "苏丹",
        suriname: "苏里南",
        eswatini: "斯威士兰",
        syria: "叙利亚",
        togo: "多哥",
        tonga: "汤加",
        tunisia: "突尼斯",
        united_States_Virgin_Islands: "美属维尔京群岛",
        uganda: "乌干达",
        uruguay: "乌拉圭",
        vatican: "梵蒂冈",
        yemen: "也门",
        yugoslavia: "南斯拉夫",
        zambia: "赞比亚",
        zimbabwe: "津巴布韦",
        china: "中国",
    }
};
