export default {
    addNew: {
        my_wallet: "私の財布",
        add_wallet: "ウォレットの追加",
        place_wallet_type: "ウォレットタイプを選択してください",
        place_wallet_name: "ウォレット名を入力してください",
        place_wallet_address: "ウォレットアドレスを入力してください",
        tab_deposit: "DeFi",
        tab_record: "DeFiレコードです",
        reference_annualization: "参考年化です",
        depositState0: "熱い進行中です",
        depositState1: "ご期待願います",
        label_amount: "金額",
        place_amount: "金額を入力してください",
        estimated_income: "予想収益",
        available_assets: "利用可能な資産",
        cancel: "キャンセル",
        all: "すべて",
        min_amount: "最小金額",
        daily: "日次生産高",
        waiting_time: "待機時間",
        product_name: "製品名です",
        buy_total: "総購入数です",
        earnings: "累積収益",

        myMessage: "私のニュース",
        place_card_back: "身分証明書の裏面写真をアップロードする」をクリックして",
        side1: "オーダー管理",
        side2: "修改密碼",
        side3: "言語",
        side4: "ログオンを終了する",
        product: "製品",
        direction: "方向",
        cycle: "取引期間",
        long_buy: "たくさん買う",
        short_sell: "空売りをする",
        open_time: "開梱時間",
        close_time: "平倉時間",
        open_price: "オープン価格",
        close_price: "平倉価格",
        number: "數量",
        profit_loss: "損益",
        commission: "手数料",
        account_type: "帳簿変更タイプ",
        type1: "貨幣口座",
        type2: "通貨口座",
        type3: "契約口座",
        type4: "オプションアカウント",
        type5: "財テク口座",
        message1: "金額を入力してください",
        message2: "口座残高が不足している",
        place_transPsd: "取引パスワードを入力してください",
        countpath: "カウントダウン終了自動オーダーページへジャンプ",
        nowpath: "今すぐジャンプ",
    },
    loan: {
        walletLink: "財布の中でこの接続を開けてください！",
        connectWallet: "ウォレットの接続",

        loan_state_4: "挫く",
        loan_state_3: "払い戻し",
        loan_state_2: "支払い済み",
        loan_state_1: "承認待ち",
        repay_state_2: "払い戻し",
        repay_state_1: "未払い",
        repay_create: "貸出期間",
        repay_state: "情勢",
        repay_times: "フェーズ",
        repay_time: "返済期間",
        repay_money: "返済総額",
        repay_total: "融資額",
        repay_pay: "返済記録",
        repay: "貸出記録",
        loan_pic4: "写真ID",
        loan_pic3: "銀行残高証明書",
        loan_pic2: "収入証明",
        loan_pic1: "所有権証明書",
        loan_pic: "クレジット・ローン（写真がはっきりと見えるようにしてください。）",
        loan_pics: "身分証明書",
        loan_pics_view: "クリックして見る",
        loan_repay_method_1: "元利一括返済" ,
        loan_repay: "返済方法",
        loan_apr: "日歩",
        loan_time: "ローンサイクル",
        loan_type_1: "日",
        loan_type_2: "月",
        loan_money: "融資額",
        loan: "奨学金",
    },
    foot: {
        nav1: "ホーム",
        nav2: "相場",
        nav3: "取り引き",
        nav4: "通貨",
        nav5: "資産",
    },
    account: {
        verifyPass: "Verify Password",
        verifySuccess: "資格認定",
        verifyFailed: "認証され",
        login: "ログイン",
        register_email: "メールボックス",
        register_phone: "携帯電話",
        label_phone: "携帯番号",
        place_phone: "携帯番号を入力してください",
        label_eMail: "メールボックス",
        place_eMail: "メールアドレスを入力してください",
        tip_eMail: "メールアドレスを正しく入力してください",
        label_password: "パスワード",
        place_password: "パスワードを入力してください",
        tip_password: "正しいパスワードを入力してください",
        no_account: "まだアカウントを持っていませんか？",
        register: "登録",
        send: "認証コードを送信" ,
        label_code: "Eメール認証コード",
        place_code: "メール認証コード",
        tip_code: "メール認証コードを入力してください",
    },
    assets: {
        title: "資産",
        total_assets: "総資産換算",
        recharge: "貨幣を充填する",
        withdraw: "貨幣をつりあげる",
        available: "使えます",
        freeze: "とうけつ",
        total: "合計＃ゴウケイ＃",
        label_bank: "貨幣種です",
        place_bank: "通貨を選択します。",
        tip_bank: "通貨を選択します。",
        label_wallet: "財布です",
        place_wallet: "お財布をお願いします。",
        tip_wallet: "お財布をお願いします。",
        label_amount: "金額です",
        place_amount: "金額を入力願います。",
        tip_amount: "金額の正確な入力をお願いします。",
        label_password: "パスワードです",
        place_password: "パスワードをお願いします",
        tip_password: "正しいパスワードをお願いします。",
        label_balance: "使えますUSDT",
        all: "すべて引き出します",
        no_password: "資金の安全のため、設置をお願いします。",
        withdraw_psd: "現金引き出しパスワードです",
        type1: "コインアカウントです",
        type2: "法貨口座です",
        type3: "契約口座です",
        type4: "スピード契約口座です",
        type5: "理財口座です",
        convert: "換算します",
        now_currency: "現在の貨幣です",
        link_type: "鎖型です",
        recharge_address: "チャージアドレスです",
        copy: "アドレスをコピーします",
        palce_amount: "チャージ数をお願いします。",
        label_address: "ウォレットアドレスです",
        palce_address: "ウォレットアドレスを入力します。",
        submit_process: "審査に提出します",
        tip1: "上記のアドレスに非USDTアセットをチャージしないでください。そうしないとアセットは回収できません。。",
        tip2: "USDTでのチャージはsimple sendの方法のみ対応しており、それ以外の方法でのチャージは当面できませんのでご了承ください。。",
        tip3: "上記のアドレスにチャージした後、ネットワークノード全体の確認が必要です。1回のネットワーク確認後に入金し、6回のネットワーク確認でコインを引き出すことができます。。",
        tip4: "あなたのチャージアドレスは頻繁に変更されませんが、繰り返しチャージすることができます;変更があった場合は、web上の掲示やメールでお知らせします。。",
        tip5: "パソコンやブラウザのセキュリティを必ず確認し、改ざんや漏洩の防止をお願いします。。",
        record_title: "取引記録です",
        label_number: "数です",
        label_record: "記録します",
        label_time: "時間です",
        label_price: "値段です",
        label_volume: "量です",
        transfer_title: "回転します",
        from: "ですから",
        to: "至ります",
        transfer_record: "回転記録です",
        label_withAddress: "コイン引き出しの場所です",
        place_withAddress: "コインの引き出し先をお願いします",
        deposit_title: "鉱山を掘ります",
        buy: "購入します",
        sell: "売ります",
        week: "サイクルです",
        daily_dividend: "日割り配当です",
        deposit_log: "採掘記録の質です",
        userinfo_nav1: "id認証です",
        userinfo_nav2: "私の共有リンクです",
        userinfo_nav3: "私のライセンスコードです",
        userinfo_nav4: "安全センターです",
        userinfo_nav5: "集金方式です",
        userinfo_nav6: "オンラインカスタマーサービス",
        userinfo_nav7: "私たちについて",
        userinfo_nav8: "ダウンロード APP",
        userinfo_nav9: "ヘルプセンター",
        logout: "ログオンの終了",
        second: "秒契約です",
        second_title: "スピード契約です",
        invite: "招待します",
        legal: "法貨取引です",
        ieo: "IEO",
        state0: "全部です",
        state1: "予約販売中です",
        state2: "申込中です",
        state3: "終わりました",
        history_record: "歴史的記録です",
        legal_tab1: "買います",
        legal_tab2: "売ります",
        transType: "取引タイプ",
        order_state: "受注ステータス",
        order_state1: "未完成です",
        order_state2: "支払い済みです",
        order_state3: "確認を延期します",
        order_state4: "権利擁護中です",
        order_state5: "キャンセルしました",
        order_state6: "完成しました",
        reset: "リセットします",
        hand_number: "取引の手数です",
        long: "たくさん作る",
        short: "空売りをする",
        confirm_order: "注文の確認",
        type: "タイプです",
        multiple_number:"倍数です",
        now_entrust: "当面の依頼です",
        all_entrust: "全て依頼します",
        history_entrust: "歴史的依頼です",
        turnover: "取引額です",
        operate: "操作します",
        delete: "削除します",
        order_details: "ご註文の詳細です",
        orderDetails_tab1: "伝票の中です",
        orderDetails_tab2: "取引中です",
        orderDetails_tab3: "平倉になりました",
        orderDetails_tab4: "請求は取り下げました",
        risk_rate: "リスク率です",
        total_pl: "ポジショントータル損益",
        closing_position: "ワンタッチ平倉です",
        position_list: "ポジションリストです",
        order_record: "ご註文記録です",
        profit_rate: "利益率です",
        buy_long: "買い上がりです",
        buy_short: "買い下がりです。",
        trans_mode: "取引モデルです",
        opening_quantity: "オープン数量です。",
        opening_number: "オープン時間です",
        place_transMount: "取引額をお願いします。",
        account_balance: "口座残高です",
        privacy_policy: "プライバシーポリシーです",
        contact_us: "連絡してきます",
        feedback: "ユーザーからのフィードバックです",
        feedback_type: "フィードバックタイプです",
        feedback_type1: "引き出し関連",
        feedback_type2: "ブロックチェーン関連です",
        label_title: "見出しです",
        place_title: "タイトル入力をお願いします。",
        label_cont: "フィードバックの内容です",
        place_cont: "内容の入力をお願いします",
        reset_password: "パスワードをリセットします",
        personal_center: "個人センターです",
        to_authenticate: "認証に行きます",
        authenticate_tip1: "·認証後は引上げ額が引き上げられます",
        authenticate_tip2: "·実名認証を通過する必要があって,やっと法貨幣取引の引き出し操作を行うことができます",
        payment_method: "集金方式です",
        label_reaName: "実名です",
        palce_reaName: "実名です",
        label_openBank: "口座開設です",
        palce_openBank: "口座開設をお願いします。",
        label_bankNumber: "カード番号です",
        place_bankNumber: "カード番号をお願いします。",
        lable_branchBank: "支店を開設します",
        place_branchBank: "口座開設支店名の入力をお願いします。",
        label_IDnumber: "住民登録番号です",
        place_IDnumber: "住民番号の入力をお願いします。",
        label_IDimg: "身分証明書の写真を持っています",
        label_IDimg2: "裏面の身分証写真です",
        tip_IDimg: "身分証明書の裏の写真をアップロードします。",
        bankCard: "銀行カードです",
        details: "詳細です",
        my_authorization_code: "私のライセンスコードです",
        my_authorization_code_tip: "私のライセンスコードです",
        login_password: "ログインパスワードです",
        trans_password: "取引暗号です",
        modify: "修正します",
        my_promotional_code: "私の普及コードです",
        promotional_code: "一般化コードです",
        promotional_code_tip: "2次元コードを読み込むかプロモーションコードを入力して、登録を確認します。",
        copy_link: "招待リンクをコピーします",
        modify_trans_psd: "取引パスワードを変更します",
        label_email_verify: "メールボックス検証です",
        place_email_verify: "認証コードを入力してください",
        send: "発送します",
        email_verify_tip: "認証コードがメールボックスに送信されます",
        label_bitTitle: "取引ペア",
    },
    currency: {
        position: "ポジション",
        drawer_title: "currency_trade",
        now: "現在",
        open: "オープン",
        volume: "出来高",
        height: "最高値",
        low: "最安値",
        buy: "買いロング",
        sell: "空売り",
        name: "名前",
        direction: "方向",
        crm_order: "注文の確認",
        now_price: "現在の価格",
        type_time: "配信時間(左スライドで高リターン)",
        second: "秒",
        buy_amount: "購入金額",
        available: "利用可能",
        service_fee: "手数料",
        min_number: "最低",
        errorTip1: "金額入力に誤りがある",
        errorTip2: "使用可能残高が不足しています",
        auto_jump: "後自動受注ジャンプ",
        keep: "注文を続行",
        revenue: "予想収益",
    },
    home: {
        quick: "クイックコインフラッシュ",
        support: "サポート",
        nav1: "シェア",
        nav2: "ウォレット",
        nav3: "情報",
        nav4: "チュートリアル",
        nav5: "カスタマーサービス",
        nav6: "マイニング",
        nav7: "会社概要",
        nav8: "新規コイン登録",
        increase: "アップリスト",
        volume: "数量",
        table_name: "名前",
        table_lastPrice: "最新価格",
        table_increase: "アップ/ダウン",
        table_volume: "ボリューム",
    },
    lockming: {
        profit: "直近（日次収益）",
        cycle: "金融サイクル",
        limit: "シングルリミット",
        dividend_time: "配当支払時期",
        every_day: "毎日",
        funds: "エスクロー資金",
        back: "満期時のリターン",
        ransom: "早期償還",
        label_amount: "誓約金額",
        place_amount: "入力してください,,",
        available: "利用可能",
        expect_income: "期待される収入",
        title: "ロックアップマイニング",
        tip: "収入を得るための採掘",
        label_today_income: "今日の予想収入",
        label_total_income: "累積収入",
        label_order: "エスクロー中の注文",
        label_number: "単一金額",
        daily_return: "日次リターン",
        subscribe: "購読",
        position: "ポジション",
        label_state: "ステータス",
        subscribe_time: "購読時間",
        expiration_time: "有効期限",
        income: "収入",
        errorTip1: "金額を入力してください",
        tip_tit: "本当に早めに退出しますか",
    },
    market: {
        spots: "在庫あり",
        contract: "契約",
        currency_trans: "货币交易",
        increase: "涨幅榜",
        volume: "成交量",
        label_name: "名称",
        label_lastPrice: "最新価格",
        table_increase: "涨跌幅",
        table_volume: "ターンオーバー",
    },
    my: {
        details: "詳細",
        help: "ヘルプセンター",
        information: "情報",
        language: "言語スイッチ",
        password: {
            account_title: "パスワードの変更",
            withdraw_title: "パスワードの変更",
            label_oldPsd: "古いパスワード",
            place_oldPsd: "古いパスワードを入力してください",
            label_newPsd: "新しいパスワード",
            place_newPsd: "新しいパスワードを入力してください",
            label_crmPsd: "パスワードの確認",
            place_crmPsd: "もう一度新しいパスワードを入力してください",
            withdraw_tip: "引き出しパスワードを設定していない場合は、空白のままにしてください",
        },
        address: {
            title: "ウォレットのアドレス",
            account_number: "口座番号",
            add_btn: "アドレスを追加",
            label_type: "タイプを選択してください",
            place_type: "タイプを選択してください",
            label_number: "口座番号",
            place_number: "口座番号を入力してください",
        },
        history: {
            title: "履歴",
            recharge_record: "リチャージ記録",
            withdraw_record: "引き出し記録",
            withdraw: "引き出し記録",
            number: "数字",
            currency: "通貨",
            state: "状態",
            state0: "保留",
            state1: "完了",
            state2: "却下",
        },
        identity: {
            title: "認証",
            label_country: "国籍を選択してください",
            place_country: "国籍を選択してください",
            label_name: "名前",
            place_name: "名前を入力してください",
            label_id: "ID番号",
            place_id: "ID/運転免許証/パスポート番号を入力してください",
        },
        my: {
            list1: "認証",
            list2: "注文管理",
            list3: "お金の記録",
            list4: "ウォレットアドレス",
            list5: "言語の切り替え",
            list6:"友達を招待する",
            list7:"カスタマーサービス",
            list8:"チュートリアル",
            list9: "パスワードの変更",
            logout: "ログアウト",
        },
        order: {
            title: "ポジション",
            direction: "方向",
            sell_price: "注文価格",
            number: "数量",
            type: "納期",
            buy_price: "買値",
            profit: "損益",
            sell_time: "sell_time",
        },
        share: {
            title: "友達を招待",
            save_ewm: "QRコードを保存",
            invite_link: "招待リンク",
            copy_address: "アドレスをコピー",
            invite_code: "登録コード",
            copy_code: "内容をコピー",
            tip: "上記のQRコードまたはリンクから登録",
        },

    },
    transaction: {
        spots: "在庫あり",
        contract: "契約",
        currency_trans: "通貨取引",
        exchange: "交換",
        sustainable: "永続的",
        warehouse: "倉庫別",
        jump_lever: "ジャンプレバー",
        sell: "売る",
        buy: "購入",
        available: "利用可能",
        check_price: "価格制限",
        market_price: "市場価格",
        number: "番号",
        max: "最大",
        bail: "マージン",
        buy_long: "買いロング",
        sell_short: "空売り",
        last_price: "直近の価格",
        now_entrust: "current_entrust",
        now_position: "現在のポジション",
        table_amount: "引き出し額",
        is_reduce: "縮小ポジションのみ",
        shi:"はい",
        fou: "いいえ",
        position_number: "保有ポジション数",
        is_profit: "実現利益",
        wei: "まだ",
        open_price: "建値",
        mark_price: "マーク価格",
        flat_price: "strong_level_price",
        trim_lever: "レバレッジの調整",
        flat_store: "クローズポジション",
        trim_bail: "マージンを調整",
        reduce_bail: "マージンを減らす",
        now_bail:"現在のポジションのマージン",
        max_add: "最大増加幅",
        refer_flat: "調整後の基準強平価",
        expect_profit: "予想損益",
    },
    common: {
        confirm: "確認",
        all: "すべて",
        close: "キャンセル",
        copy_success: "コピー成功！",
        copy_error: "コピー失敗！" ,
        place_input: "内容を入力してください" ,
        more: "詳細",
        main: "マスタ図",
        sub: "ふくず",
        success: "成功！",
        cancel_tip: "本当にキャンセルしますか？" ,
        notice: "に知らせる",
        logout_tip: "終了を確認しますか？",
        tip: "ヒント",
        day: "日",
        empty: "データなし"
    },
    message: {
		cancel_order: "委任を取り消すかどうか?",
		stop_out: "平倉庫を強制するか?",
	},
    params: {
        transType0: "現物",
        transType1: "契約",
        directionType0: "購入する",
        directionType1: "売り出す",
        lockStateType0: "実行中",
        lockStateType1: "終了",
        lockStateType2: "キャンセル済み",
	},
    city: {
		albania: "アルバニア",
		algeria: "アルジェリア",
		argentina: "アルゼンチン",
		armenia: "アルメニア",
		australia: "オーストラリア",
		pakistan: "パキスタン",
		austria: "オーストリア",
		bahrain: "オーストリア",
		belgium: "ベルギー",
		bosnia_and_Herzegovina: "ボスニア・ヘルツェゴビナ",
		brazil: "ブラジル",
		brunei: "ブルネイ",
		bulgaria: "ブルガリア",
		cambodia: "カンボジア",
		canada: "カナダ",
		cameroon: "カメルーン",
		chile: "チリ",
		colombia: "コロンビア",
		costa_Rica: "コスタリカ",
		croatia: "クロアチア",
		cyprus: "キプロス",
		czech_Republic: "チェコ共和国",
		denmark: "デンマーク",
		dominican_Republic: "ドミニカ共和国",
		egypt: "エジプト",
		estonia: "エストニア",
		ethiopia: "エチオピア",
		finland: "フィンランド",
		france: "フランス",
		georgia: "グルジア",
		germany: "ドイツ",
		ghana: "ガーナ",
		greece: "ギリシャ",
		guyana: "ガイアナ",
		honduras: "ホンジュラス",
		hong_Kong_China: "中国香港",
		hungary: "ハンガリー",
		iceland: "アイスランド",
		ireland: "アイルランド",
		italy: "イタリア",
		india: "インド",
		indonesia: "インドネシア",
		israel: "イスラエル",
		iran: "イラン",
		iraq: "イラク",
		japan: "日本",
		kazakstan: "カザフスタン",
		kenya: "ケニア",
		korea: "韓国",
		kuwait: "クウェート",
		kyrgyzstan: "キルギス",
		laos: "ラオス",
		latvia: "ラトビア",
		lithuania: "リトアニア",
		luxembourg: "ルクセンブルク",
		macao_China: "中国マカオ",
		macedonia: "マケドニア",
		malaysia: "マレーシア",
		malta: "マルタ",
		mexico: "メキシコ",
		moldova: "モルドバ",
		monaco: "モナコ",
		mongolia: "モンゴル",
		montenegro: "モンテネグロ共和国",
		morocco: "モロッコ",
		myanmar: "ミャンマー",
		netherlands: "オランダ",
		new_Zealand: "ニュージーランド",
		nepal: "ネパール",
		nigeria: "ナイジェリア",
		norway: "ノルウェー",
		oman: "オマーン",
		palestine: "パレスチナ",
		panama: "パナマ",
		paraguay: "パラグアイ",
		peru: "ペルー",
		philippines: "フィリピン",
		poland: "ポーランド",
		portugal: "ポルトガル",
		puerto_Rico: "プエルトリコ",
		qatar: "カタール",
		romania: "ルーマニア",
		russia: "ロシア",
		republic_of_Trinidad_and_Tobago: "トリニダード・トバゴ共和国",
		rwanda: "ルワンダ",
		saudi_Arabia: "サウジアラビア",
		serbia: "セルビア",
		singapore: "シンガポール",
		slovakia: "スロバキア",
		slovenia: "スロベニア",
		south_Africa: "南アフリカ共和国",
		spain: "スペイン",
		sri_Lanka: "スリランカ",
		sweden: "スウェーデン",
		switzerland: "スイス",
		taiwan_China: "中国台湾",
		tajikistan: "タジキスタン",
		tanzania: "タンザニア",
		thailand: "タイ",
		turkey: "トルコ",
		turkmenistan: "トルクメニスタン",
		ukraine: "ウクライナ",
		united_Arab_Emirates: "アラブ首長国連邦",
		united_Kingdom: "英国",
		united_States: "アメリカ",
		uzbekistan: "ウズベキスタン",
		venezuela: "ベネズエラ",
		vietnam: "ベトナム",
		afghanistan: "アフガニスタン",
		angola: "アンゴラ",
		azerbaijan: "アゼルバイジャン",
		bangladesh: "バングラデシュ",
		belarus: "ベラルーシ",
		belize: "ベリーズ",
		benin: "ベニン",
		bhutan: "ブータン",
		bolivia: "ボリビア",
		botswana: "ボツワナ",
		british_Virgin_Islands: "イギリス領ヴァージン諸島",
		burkina_Faso: "ブルキナファソ",
		burundi: "ブルンジ",
		cape_Verde: "カーボベルデ角",
		cayman_Islands: "ケイマン諸島",
		central_African_Republic: "中央アフリカ共和国",
		chad: "チャド",
		comoros: "コモロ",
		the_Republic_of_the_Congo: "コンゴ（布）",
		democratic_Republic_of_the_Congo: "コンゴ（金）",
		djibouti: "ジブチ",
		ecuador: "エクアドル",
		el_Salvador: "エルサルバドル",
		equatorial_Guinea: "赤道ギニア",
		eritrea: "エリトリア",
		fiji: "フィジー",
		gabon: "ガボン",
		gambia: "ガンビア",
		greenland: "グリーンランド",
		guatemala: "グアテマラ",
		guinea: "ギニア",
		haiti: "ハイチ",
		isle_of_Man: "マーン島",
		cote_d_Ivoire: "コートジボワール",
		jamaica: "ジャマイカ",
		jordan: "ヨルダン",
		lebanon: "レバノン",
		lesotho: "レソト",
		liberia: "リベリア",
		libya: "リビア",
		madagascar: "マダガスカル",
		malawi: "マラウイ",
		maldives: "モルディブ",
		mali: "マリ",
		mauritania: "モーリタニア",
		mauritius: "モーリシャス",
		mozambique: "モザンビーク",
		namibia: "ナミビア",
		nicaragua: "ニカラグア",
		republic_of_Niger: "ニジェール",
		north_Korea: "朝鲜",
		reunion: "レユニオン",
		san_Marino: "サンマリノ",
		senegal: "セネガル",
		sierra_Leone: "シエラレオネ",
		somalia: "ソマリア",
		sudan: "スーダン",
		suriname: "スリナム",
		eswatini: "スワジランド",
		syria: "シリア",
		togo: "トーゴ",
		tonga: "トンガ",
		tunisia: "チュニジア",
		united_States_Virgin_Islands: "アメリカ領ヴァージン諸島",
		uganda: "ウガンダ",
		uruguay: "ウルグアイ",
		vatican: "バチカン",
		yemen: "イエメン",
		yugoslavia: "ユーゴスラビア",
		zambia: "ザンビア",
		zimbabwe: "ジンバブエ",
		china: "中国",
	}
};
