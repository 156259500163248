export default {
    addNew: {
        my_wallet: "Meine Brieftasche",
        add_wallet: "Brieftasche hinzufügen",
        place_wallet_type: "Bitte wählen Sie den Wallet-Typ",
        place_wallet_name: "Bitte geben Sie den Wallet-Namen ein",
        place_wallet_address: "Bitte geben Sie die Wallet-Adresse ein",
        reference_annualization: "Referencia Anualizada",
        state: "Situación",
        term: "Plazos",
        depositState0: "Caliente en marcha.",
        depositState1: "Esperamos",
        label_amount: "Suma de dinero",
        place_amount: "Introduzca el importe",
        estimated_income: "Ingresos previstos",
        available_assets: "Activo disponible",
        cancel: "Anulaciones",
        all: "Completo",
        min_amount: "Importe mínimo",
        daily: "Producción diaria",
        waiting_time: "Tiempo de espera",
        product_name: "Cloruro de dietilamonio",
        buy_total: "Número total de compras",
        earnings: "Ganancia acumulada",

        myMessage: "Message",
        place_card_back: "Klicken Sie hier, um ein Foto von der Rückseite Ihres Personalausweises hochzuladen",
        side1: "Auftragsverwaltung",
        side2: "Passwort ändern",
        side3: "Sprache",
        side4: "Log out of login",
        product: "Produkt",
        direction: "Richtung",
        cycle: "Transaktionsperiode",
        long_buy: "Buy long",
        short_sell: "Short sell",
        open_time: "Öffnungszeiten",
        close_time: "Closing Time",
        open_price: "Eröffnungspreis",
        close_price: "Closing price",
        number: "Menge",
        profit_loss: "Gewinn und Verlust",
        commission: "Bearbeitungsgebühr",
        account_type: "Kontowechseltyp",
        type1: "Münzkonto",
        type2: "Legal Account",
        type3: "Vertragskonto",
        type4: "Optionskonto",
        type5: "Wealth account",
        message1: "Bitte geben Sie den Betrag ein",
        message2: "Unzureichender Kontostand",
        place_transPsd: "Please enter transaction password",
        countpath: "Nach dem Countdown automatisch zur Bestellseite springen",
        nowpath: "Sofort springen",
    },
    loan: {
        walletLink: "Bitte öffnen Sie diese Verbindung in Ihrem Geldbeutel!",
        connectWallet: "Wallet verbinden",

        loan_state_4: "Scheitern",
        loan_state_3: "Zurückgezahlt",
        loan_state_2: "Ausgezahlt",
        loan_state_1: "In Erwartung der Genehmigung",
        repay_state_2: "Zurückgezahlt",
        repay_state_1: "Nicht-Rückzahlung",
        repay_create: "Leihfrist",
        repay_state: "Stand der Dinge",
        repay_times: "Phase",
        repay_time: "Rückzahlungsfrist",
        repay_money: "Gesamtrückzahlung",
        repay_total: "Kreditbetrag",
        repay_pay: "Rückzahlungsaufzeichnungen",
        repay: "Darlehensunterlagen",
        loan_pic4: "Foto-ID",
        loan_pic3: "Kontoauszug",
        loan_pic2: "Einkommensnachweis",
        loan_pic1: "Eigentumsnachweis",
        loan_pic: "Kredite (bitte stellen Sie sicher, dass die Bilder gut sichtbar sind)",
        loan_pics: "Identifikationsformular",
        loan_pics_view: "Klicken Sie zur Ansicht",
        loan_repay_method_1: "Pauschale Rückzahlung von Kapital und Zinsen" ,
        loan_repay: "Rückzahlungsmethode",
        loan_apr: "Tageszinssatz",
        loan_time: "Darlehenszyklus",
        loan_type_1: "Tag",
        loan_type_2: "Mond",
        loan_money: "Kreditbetrag",
        loan: "Studentenkredit",
    },
    foot: {
        nav1: "Startseite",
        nav2: "Markt",
        nav3: "Spot",
        nav4: "Futures",
        nav5: "Vermögen",
    },
    account: {
        verifyPass: "Verify Password",
        verifySuccess: "Zertifiziert",
        verifyFailed: "Nicht zertifiziert",
        login: "Anmelden",
        register_email: "E-Mail-Registrierung",
        register_phone: "Telefonregistrierung",
        label_phone: "Telefonnummer",
        place_phone: "Bitte geben Sie Ihre Telefonnummer ein",
        label_eMail: "E-Mail",
        place_eMail: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        tip_eMail: "Bitte geben Sie Ihre korrekte E-Mail-Adresse ein",
        label_password: "Passwort",
        place_password: "Bitte geben Sie Ihr Passwort ein",
        tip_password: "Bitte geben Sie ein korrektes Passwort ein",
        no_account: "Sie haben noch kein Konto?",
        register: "Registrieren",
        send: "Code senden",
        label_code: "Bestätigungscode",
        place_code: "Bestätigungscode",
        tip_code: "Bitte geben Sie den Bestätigungscode ein",
        label_invitation: "Einladungscode",
        place_invitation: "Bitte geben Sie den Einladungscode ein",
    },
    assets: {
        title: "Vermögenswerte",
        total_assets: "Gesamtwert der Vermögenswerte",
        recharge: "Einzahlen",
        withdraw: "Abheben",
        available: "Verfügbar",
        freeze: "Gesperrt",
        total: "Gesamt",
        label_bank: "Währung",
        place_bank: "Bitte wählen Sie die Währung",
        tip_bank: "Bitte wählen Sie die Währung",
        label_wallet: "Wallet",
        place_wallet: "Bitte wählen Sie das Wallet",
        tip_wallet: "Bitte wählen Sie das Wallet",
        label_amount: "Betrag",
        place_amount: "Bitte geben Sie den Betrag ein",
        tip_amount: "Bitte geben Sie den Betrag korrekt ein",
        label_password: "Passwort",
        place_password: "Bitte geben Sie das Passwort ein",
        tip_password: "Bitte geben Sie ein korrektes Passwort ein",
        label_balance: "Verfügbares USDT",
        all: "Alles abheben",
        no_password: "Bitte einrichten, um Ihr Geld sicher zu halten",
        withdraw_psd: "Abhebepasswort",
        type1: "Spot-Konto",
        type2: "Fiat-Konto",
        type3: "Futures-Konto",
        type4: "Schnellfutures-Konto",
        type5: "Vermögensverwaltungskonto",
        convert: "Äquivalent",
        now_currency: "Aktuelle Währung",
        link_type: "Link-Typ",
        recharge_address: "Einzahlungsadresse",
        copy: "Adresse kopieren",
        palce_amount: "Bitte geben Sie die Einzahlungsmenge ein",
        label_address: "Wallet-Adresse",
        palce_address: "Bitte geben Sie die Wallet-Adresse ein",
        submit_process: "Überprüfung einreichen",
        tip1: "Bitte laden Sie keine anderen Vermögenswerte als USDT auf die oben genannte Adresse auf, da die Vermögenswerte nicht wiederhergestellt werden können.",
        tip2: "Die Einzahlung von USDT wird nur über die Methode Simple Send unterstützt. Einzahlungen über andere Methoden können vorübergehend nicht gutgeschrieben werden. Wir bitten um Ihr Verständnis.",
        tip3: "Nachdem Sie auf die oben genannte Adresse eingezahlt haben, muss sie von den Knoten des gesamten Netzwerks bestätigt werden. Nach einer Netzwerkbestätigung wird Ihr Guthaben gutgeschrieben. Sie können nach 6 Netzwerkbestätigungen eine Auszahlung vornehmen.",
        tip4: "Ihre Einzahlungsadresse ändert sich nicht häufig und kann mehrmals verwendet werden. Im Falle einer Änderung werden wir Sie nach Möglichkeit über Ankündigungen im Netzwerk oder per E-Mail benachrichtigen.",
        tip5: "Bitte stellen Sie sicher, dass Ihr Computer und Ihr Browser sicher sind, um die Änderung oder den Diebstahl von Informationen zu verhindern.",
        record_title: "Transaktionsverlauf",
        label_number: "Menge",
        label_record: "Aufzeichnung",
        label_time: "Zeit",
        label_price: "Preis",
        label_volume: "Volumen",
        transfer_title: "Übertragung",
        from: "Von",
        to: "An",
        transfer_record: "Übertragungsverlauf",
        label_withAddress: "Auszahlungsadresse",
        place_withAddress: "Bitte geben Sie zuerst Ihre Auszahlungsadresse ein",
        deposit_title: "Staking Mining",
        buy: "Kaufen",
        sell: "Verkaufen",
        week: "Woche",
        daily_dividend: "Tägliche Dividende",
        deposit_log: "Staking Mining-Protokoll",
        userinfo_nav1: "Identitätsprüfung",
        userinfo_nav2: "Mein Empfehlungslink",
        userinfo_nav3: "Mein Autorisierungscode",
        userinfo_nav4: "Sicherheitszentrale",
        userinfo_nav5: "Zahlungsmethoden",
        userinfo_nav6: "Kundensupport",
        userinfo_nav7: "Über uns",
        userinfo_nav8: "App herunterladen",
        userinfo_nav9: "Hilfezentrum",
        logout: "Abmelden",
        second: "Sekunden-Vertrag",
        second_title: "Sekunden-Vertrag",
        invite: "Einladen",
        legal: "Fiat-Handel",
        ieo: "IEO",
        state0: "Alle",
        state1: "Vorverkauf",
        state2: "Abonnement",
        state3: "Beendet",
        history_record: "Verlauf",
        legal_tab1: "Ich möchte kaufen",
        legal_tab2: "Ich möchte verkaufen",
        transType: "Transaktionstyp",
        order_state: "Auftragsstatus",
        order_state1: "Unvollständig",
        order_state2: "Bezahlt",
        order_state3: "Verlängerte Bestätigung",
        order_state4: "In Streit",
        order_state5: "Abgebrochen",
        order_state6: "Abgeschlossen",
        reset: "Zurücksetzen",
        hand_number: "Handelsvolumen",
        long: "Long-Position",
        short: "Short-Position",
        confirm_order: "Bestellung bestätigen",
        type: "Typ",
        multiple_number: "Multiplikator",
        now_entrust: "Aktueller Auftrag",
        all_entrust: "Alle Aufträge",
        history_entrust: "Historische Aufträge",
        turnover: "Umsatz",
        operate: "Betrieb",
        delete: "Löschen",
        order_details: "Auftragsdetails",
        orderDetails_tab1: "Aufträge im Auftrag",
        orderDetails_tab2: "Handelsaufträge",
        orderDetails_tab3: "Position geschlossen",
        orderDetails_tab4: "Auftrag storniert",
        risk_rate: "Risikorating",
        total_pl: "Gesamter Positionsgewinn/Verlust",
        closing_position: "Alle Positionen schließen",
        position_list: "Positionenliste",
        order_record: "Aufzeichnung der Bestellungen",
        profit_rate: "Gewinnrate",
        buy_long: "Long kaufen",
        buy_short: "Short kaufen",
        trans_mode: "Handelsmodus",
        opening_quantity: "Öffnungsmenge",
        opening_number: "Öffnungszeit",
        place_transMount: "Geben Sie den Transaktionsbetrag ein",
        account_balance: "Kontostand",
        privacy_policy: "Datenschutzrichtlinie",
        contact_us: "Kontaktiere uns",
        feedback: "Benutzer-Feedback",
        feedback_type: "Feedback-Typ",
        feedback_type1: "Abhebungsbezogen",
        feedback_type2: "Blockchain-bezogen",
        label_title: "Titel",
        place_title: "Bitte geben Sie einen Titel ein",
        label_cont: "Feedback-Inhalt",
        place_cont: "Bitte geben Sie den Inhalt ein",
        reset_password: "Passwort zurücksetzen",
        personal_center: "Persönliches Zentrum",
        to_authenticate: "Zur Authentifizierung gehen",
        authenticate_tip1: "· Die Authentifizierung erhöht das Auszahlungslimit",
        authenticate_tip2: "· Für Fiat-Handel und Auszahlungsvorgänge ist eine Identitätsprüfung erforderlich",
        payment_method: "Zahlungsmethode",
        label_reaName: "Vollständiger Name",
        palce_reaName: "Vollständiger Name",
        label_openBank: "Bankverbindung",
        palce_openBank: "Bitte geben Sie die Bankverbindung ein",
        label_bankNumber: "Bankkontonummer",
        place_bankNumber: "Bitte geben Sie die Bankkontonummer ein",
        lable_branchBank: "Filiale",
        place_branchBank: "Bitte geben Sie den Namen der Bankfiliale ein",
        label_IDnumber: "Ausweisnummer",
        place_IDnumber: "Bitte geben Sie die Ausweisnummer ein",
        label_IDimg: "Bild des Ausweises (Vorderseite)",
        label_IDimg2: "Bild des Ausweises (Rückseite)",
        tip_IDimg: "Bitte laden Sie ein Bild der Ausweisrückseite hoch",
        bankCard: "Bankkarte",
        details: "Details",
        my_authorization_code: "Mein Autorisierungscode",
        my_authorization_code_tip: "Mein Autorisierungscode",
        login_password: "Anmeldekennwort",
        trans_password: "Transaktionskennwort",
        modify: "Ändern",
        my_promotional_code: "Mein Werbecode",
        promotional_code: "Werbecode",
        promotional_code_tip: "Scannen Sie das obige QR-Code-Muster oder geben Sie den Werbecode ein, um sich zu registrieren",
        copy_link: "Einladungslink kopieren",
        modify_trans_psd: "Transaktionskennwort ändern",
        label_email_verify: "E-Mail-Verifizierung",
        place_email_verify: "Bitte geben Sie den Verifizierungscode ein",
        send: "Senden",
        email_verify_tip: "Der Verifizierungscode wird an Ihre E-Mail-Adresse gesendet",
        label_bitTitle: "Handelspaar",
        label_profit: "Gewinn",
    },
    currency: {
        position: "Position",
        drawer_title: "Währungshandel",
        now: "Aktuell",
        open: "Eröffnung",
        volume: "Handelsvolumen",
        height: "Hoch",
        low: "Tief",
        buy: "Long",
        sell: "Short",
        name: "Name",
        direction: "Richtung",
        crm_order: "Bestellung bestätigen",
        now_price: "Aktueller Preis",
        type_time: "Lieferzeit (zum Erhöhen nach links wischen)",
        second: "Sekunden",
        buy_amount: "Kaufbetrag",
        available: "Verfügbar",
        service_fee: "Gebühr",
        min_number: "Minimum",
        errorTip1: "Falsche Betragsangabe",
        errorTip2: "Unzureichendes Guthaben",
        auto_jump: "Wird automatisch zur Bestellung weitergeleitet",
        keep: "Weiter bestellen",
        revenue: "Voraussichtliche Rendite",
    },
    home: {
        quick: "Schnelle Einzahlung",
        support: "Unterstützung",
        nav1: "Teilen",
        nav2: "Brieftasche",
        nav3: "Nachrichten",
        nav4: "Tutorial",
        nav5: "Kundenservice",
        nav6: "Mining",
        nav7: "Über uns",
        nav8: "Neue Münzen kaufen",
        increase: "Top-Anstiege",
        volume: "Handelsvolumen",
        table_name: "Name",
        table_lastPrice: "Letzter Preis",
        table_increase: "Änderung",
        table_volume: "Volumen",
    },
    lockming: {
        profit: "In den letzten Tagen (tägliche Einnahmen)",
        cycle: "Finanzierungszyklus",
        limit: "Einzelbegrenzung",
        dividend_time: "Dividendenzeit",
        every_day: "Täglich",
        funds: "Verwaltetes Kapital",
        back: "Rückgabe bei Fälligkeit",
        ransom: "Vorzeitige Rückgabe",
        label_amount: "Stake-Betrag",
        place_amount: "Bitte eingeben...",
        available: "Verfügbar",
        expect_income: "Voraussichtliches Einkommen",
        title: "Staking Mining",
        tip: "Mining für kontinuierlichen Gewinn",
        label_today_income: "Voraussichtliches Einkommen heute",
        label_total_income: "Kumuliertes Einkommen",
        label_order: "Verwaltete Aufträge",
        label_number: "Einzelbetrag",
        daily_return: "Tägliche Rendite",
        subscribe: "Abonnieren",
        position: "Position",
        label_state: "Status",
        subscribe_time: "Abonnementzeitpunkt",
        expiration_time: "Fälligkeitsdatum",
        income: "Einkommen",
        errorTip1: "Bitte Betrag eingeben",
        tip_tit: "Möchten Sie vorzeitig zurücktreten?",
    },
    market: {
        spots: "Spot",
        contract: "Futures",
        currency_trans: "Währungshandel",
        increase: "Top-Anstiege",
        volume: "Handelsvolumen",
        label_name: "Name",
        label_lastPrice: "Letzter Preis",
        table_increase: "Änderung",
        table_volume: "Volumen",
    },
    my: {
        details: "Details",
        help: "Hilfezentrum",
        information: "Nachrichten",
        language: "Sprachumschaltung",
        password: {
            account_title: "Passwort ändern",
            withdraw_title: "Passwort ändern",
            label_oldPsd: "Altes Passwort",
            place_oldPsd: "Bitte altes Passwort eingeben",
            label_newPsd: "Neues Passwort",
            place_newPsd: "Bitte neues Passwort eingeben",
            label_crmPsd: "Passwort bestätigen",
            place_crmPsd: "Bitte das neue Passwort erneut eingeben",
            withdraw_tip: "Wenn Sie zuvor kein Abhebepasswort festgelegt haben, lassen Sie es leer",
        },
        address: {
            title: "Brieftaschenadresse",
            account_number: "Kontonummer",
            add_btn: "Adresse hinzufügen",
            label_type: "Typ auswählen",
            place_type: "Bitte Typ auswählen",
            label_number: "Kontonummer",
            place_number: "Bitte Kontonummer eingeben",
        },
        history: {
            title: "Verlauf",
            recharge_record: "Einzahlungsverlauf",
            withdraw_record: "Abhebungsverlauf",
            recharge: "Einzahlung",
            withdraw: "Abhebung",
            number: "Menge",
            currency: "Währung",
            state: "Status",
            state0: "Ausstehend",
            state1: "Abgeschlossen",
            state2: "Abgelehnt",
        },
        identity: {
            title: "Authentifizierung",
            label_country: "Land auswählen",
            place_country: "Bitte Land auswählen",
            label_name: "Name",
            place_name: "Bitte geben Sie Ihren Namen ein",
            label_id: "Ausweisnummer",
            place_id: "Bitte geben Sie Ihre Personalausweis-/Führerschein-/Reisepassnummer ein",
        },
        my: {
            list1: "Authentifizierung",
            list2: "Bestellverwaltung",
            list3: "Kapitalaufzeichnung",
            list4: "Brieftaschenadresse",
            list5: "Sprache wechseln",
            list6: "Freunde einladen",
            list7: "Kundenservice",
            list8: "Tutorial",
            list9: "Passwort ändern",
            logout: "Abmelden",
        },
        order: {
            title: "Position",
            direction: "Richtung",
            sell_price: "Bestellpreis",
            number: "Menge",
            type: "Lieferzeit",
            buy_price: "Kaufpreis",
            profit: "Gewinn/Verlust",
            sell_time: "Verkaufszeit",
        },
        share: {
            title: "Freunde einladen",
            save_ewm: "QR-Code speichern",
            invite_link: "Einladungslink",
            copy_address: "Adresse kopieren",
            invite_code: "Einladungscode",
            copy_code: "Inhalt kopieren",
            tip: "Melden Sie sich über den obigen QR-Code oder den Link an",
        },
    },
    transaction: {
        spots: "Spot",
        contract: "Vertrag",
        currency_trans: "Währungshandel",
        exchange: "Blitztausch",
        sustainable: "Nachhaltig",
        warehouse: "Lagerhaus",
        jump_lever: "Hebel wechseln",
        sell: "Verkaufen",
        buy: "Kaufen",
        available: "Verfügbar",
        check_price: "Limitpreis",
        market_price: "Marktpreis",
        number: "Menge",
        max: "Maximal",
        bail: "Margin",
        buy_long: "Long kaufen",
        sell_short: "Short verkaufen",
        last_price: "Letzter Preis",
        now_entrust: "Historische Positionen",
        now_position: "Aktuelle Positionen",
        table_amount: "Abhebungsbetrag",
        is_reduce: "Nur reduzieren",
        shi: "Ja",
        fou: "Nein",
        position_number: "Positionsmenge",
        is_profit: "Realisierter Gewinn",
        wei: "Unrealisiert",
        open_price: "Eröffnungspreis",
        mark_price: "Marktpreis",
        flat_price: "Liquidationspreis",
        trim_lever: "Hebel anpassen",
        flat_store: "Position schließen",
        trim_bail: "Margin anpassen",
        reduce_bail: "Margin reduzieren",
        now_bail: "Aktuelle Margin",
        max_add: "Maximal hinzufügbar",
        refer_flat: "Referenz-Liquidationspreis nach Anpassung",
        expect_profit: "Erwarteter Gewinn/Verlust",
    },
    common: {
        confirm: "Bestätigen",
        Cancel: "Abbrechen",
        all: "Alle",
        close: "Schließen",
        copy_success: "Kopieren erfolgreich!",
        copy_error: "Kopieren fehlgeschlagen!",
        place_input: "Bitte Inhalt eingeben",
        more: "Mehr",
        main: "Hauptchart",
        sub: "Unterchart",
        success: "Erfolg!",
        cancel_tip: "Möchten Sie wirklich abbrechen?",
        notice: "Benachrichtigung",
        logout_tip: "Möchten Sie sich abmelden?",
        tip: "Hinweis",
        day: "Tag",
        place_inner: "Bitte Inhalt eingeben",
        fail_banner: "Nicht genügend Guthaben",
        empty: "Keine Daten vorhanden",
    },
    message: {
		cancel_order: "Möchten Sie den Auftrag wirklich stornieren?",
        stop_out: "Möchten Sie die Zwangsschließung wirklich durchführen?",
	},
    params: {
        transType0: "Spot",
        transType1: "Vertrag",
        directionType0: "Kaufen",
        directionType1: "Verkaufen",
        lockStateType0: "In Bearbeitung",
        lockStateType1: "Abgeschlossen",
        lockStateType2: "Abgebrochen",
	},
	city: {
        albania: "Albanien",
        algeria: "Algerien",
        argentina: "Argentinien",
        armenia: "Armenien",
        australia: "Australien",
        pakistan: "Pakistan",
        austria: "Österreich",
        bahrain: "Bahrain",
        belgium: "Belgien",
        bosnia_and_Herzegovina: "Bosnien und Herzegowina",
        brazil: "Brasilien",
        brunei: "Brunei",
        bulgaria: "Bulgarien",
        cambodia: "Kambodscha",
        canada: "Kanada",
        cameroon: "Kamerun",
        chile: "Chile",
        colombia: "Kolumbien",
        costa_Rica: "Costa Rica",
        croatia: "Kroatien",
        cyprus: "Zypern",
        czech_Republic: "Tschechische Republik",
        denmark: "Dänemark",
        dominican_Republic: "Dominikanische Republik",
        egypt: "Ägypten",
        estonia: "Estland",
        ethiopia: "Äthiopien",
        finland: "Finnland",
        france: "Frankreich",
        georgia: "Georgien",
        germany: "Deutschland",
        ghana: "Ghana",
        greece: "Griechenland",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hongkong, China",
        hungary: "Ungarn",
        iceland: "Island",
        ireland: "Irland",
        italy: "Italien",
        india: "Indien",
        indonesia: "Indonesien",
        israel: "Israel",
        iran: "Iran",
        iraq: "Irak",
        japan: "Japan",
        kazakstan: "Kasachstan",
        kenya: "Kenia",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kirgisistan",
        laos: "Laos",
        latvia: "Lettland",
        lithuania: "Litauen",
        luxembourg: "Luxemburg",
        macao_China: "Macao, China",
        macedonia: "Mazedonien",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexiko",
        moldova: "Moldawien",
        monaco: "Monaco",
        mongolia: "Mongolei",
        montenegro: "Montenegro",
        morocco: "Marokko",
        myanmar: "Myanmar",
        netherlands: "Niederlande",
        new_Zealand: "Neuseeland",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norwegen",
        oman: "Oman",
        palestine: "Palästina",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippinen",
        poland: "Polen",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Katar",
        romania: "Rumänien",
        russia: "Russland",
        republic_of_Trinidad_and_Tobago: "Republik Trinidad und Tobago",
        rwanda: "Ruanda",
        saudi_Arabia: "Saudi-Arabien",
        serbia: "Serbien",
        singapore: "Singapur",
        slovakia: "Slowakei",
        slovenia: "Slowenien",
        south_Africa: "Südafrika",
        spain: "Spanien",
        sri_Lanka: "Sri Lanka",
        sweden: "Schweden",
        switzerland: "Schweiz",
        taiwan_China: "Taiwan, China",
        tajikistan: "Tadschikistan",
        tanzania: "Tansania",
        thailand: "Thailand",
        turkey: "Türkei",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Vereinigte Arabische Emirate",
        united_Kingdom: "Vereinigtes Königreich",
        united_States: "Vereinigte Staaten",
        uzbekistan: "Usbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Aserbaidschan",
        bangladesh: "Bangladesch",
        belarus: "Weißrussland",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivien",
        botswana: "Botswana",
        british_Virgin_Islands: "Britische Jungferninseln",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Kap Verde",
        cayman_Islands: "Kaimaninseln",
        central_African_Republic: "Zentralafrikanische Republik",
        chad: "Tschad",
        comoros: "Komoren",
        the_Republic_of_the_Congo: "Republik Kongo",
        democratic_Republic_of_the_Congo: "Demokratische Republik Kongo",
        djibouti: "Dschibuti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Äquatorialguinea",
        eritrea: "Eritrea",
        fiji: "Fidschi",
        gabon: "Gabun",
        gambia: "Gambia",
        greenland: "Grönland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: "Isle of Man",
        cote_d_Ivoire: "Elfenbeinküste",
        jamaica: "Jamaika",
        jordan: "Jordanien",
        lebanon: "Libanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libyen",
        madagascar: "Madagaskar",
        malawi: "Malawi",
        maldives: "Malediven",
        mali: "Mali",
        mauritania: "Mauretanien",
        mauritius: "Mauritius",
        mozambique: "Mosambik",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Niger",
        north_Korea: "Nordkorea",
        reunion: "Réunion",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syrien",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunesien",
        united_States_Virgin_Islands: "Amerikanische Jungferninseln",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatikanstadt",
        yemen: "Jemen",
        yugoslavia: "Jugoslawien",
        zambia: "Sambia",
        zimbabwe: "Simbabwe",
        china: "China",
    }
};
