export default {
    addNew: {
        my_wallet: "Ví của tôi",
        add_wallet: "Thêm ví",
        place_wallet_type: "Vui lòng chọn loại ví",
        place_wallet_name: "Vui lòng nhập tên ví",
        place_wallet_address: "Vui lòng nhập địa chỉ ví",

        reference_annualization: "Năm tham chiếu",
        state: "Trạng thái",
        term: "Thời hạn",
        depositState0: "Đang bốc lửa.",
        depositState1: "Mong chờ",
        label_amount: "Số tiền",
        place_amount: "Vui lòng nhập số tiền",
        estimated_income: "Thu nhập dự kiến",
        available_assets: "Tài sản có sẵn",
        cancel: "Hủy bỏ",
        all: "Tất cả",
        min_amount: "Số tiền tối thiểu",
        daily: "Sản lượng hàng ngày",
        waiting_time: "Thời gian chờ",
        product_name: "Tên sản phẩm",
        buy_total: "Mua tổng số lượng",
        earnings: "Thu nhập tích lũy",

        myMessage: "Thông tin của tôi",
        place_card_back: "Nhấn vào Upload ảnh ngược của ID",
        side1: "Quản lý đơn hàng",
        side2: "Thay đổi mật khẩu",
        side3: "Ngôn ngữ",
        side4: "Đăng xuất",
        product: "Sản phẩm",
        direction: "Hướng dẫn",
        cycle: "Trong thời gian giao dịch",
        long_buy: "Mua dài",
        short_sell: "Bán ngắn",
        open_time: "Thời gian mở vị trí",
        close_time: "Thời gian đóng vị trí",
        open_price: "Giá mở vị trí",
        close_price: "Giá đóng",
        number: "Số lượng",
        profit_loss: "Lợi nhuận và lỗ",
        commission: "Phí xử lý",
        account_type: "Loại tài khoản",
        type1: "Tài khoản Coin",
        type2: "Tài khoản fiat",
        type3: "Tài khoản hợp đồng",
        type4: "Tài khoản tùy chọn",
        type5: "Tài khoản tài chính",
        message1: "Vui lòng nhập số tiền",
        message2: "Không đủ số dư tài khoản",
        place_transPsd: "Vui lòng nhập mật khẩu giao dịch",
        countpath: "Kết thúc đếm ngược Tự động chuyển đến trang đặt hàng",
        nowpath: "Nhảy ngay",
    },
    loan: {
        tester: "Tài khoản trải nghiệm",
        rechargePic: "Chứng từ chuyển khoản",

        walletLink: "Vui lòng mở kết nối này trong ví của bạn！",
        connectWallet: "Ví kết nối",

        loan_state_4:"Thất bại",
        loan_state_3:"Đã thanh toán",
        loan_state_2:"Đã phát hành",
        loan_state_1:"Được kiểm tra.",
        repay_state_2:"Đã thanh toán",
        repay_state_1:"Chưa hoàn trả",
        repay_create:"Thời gian cho vay",
        repay_state:"Trạng thái",
        repay_times:"Số kỳ",
        repay_time:"Thời gian trả nợ",
        repay_money:"Tổng số tiền hoàn trả",
        repay_total:"Số tiền vay",
        repay_pay:"Hồ sơ trả nợ",
        repay:"Hồ sơ vay vốn",
        loan_pic4:"Hình ảnh ID",
        loan_pic3:"Hóa đơn ngân hàng",
        loan_pic2:"Bằng chứng thu nhập",
        loan_pic1:"Giấy chứng nhận tài sản",
        loan_pic:"Cho vay tín dụng (đảm bảo hình ảnh rõ ràng)",
        loan_pics:"Tài liệu chứng minh",
        loan_pics_view:"Click để xem",
        loan_repay_method_1:"Trả lãi một lần.",
        loan_repay:"Phương thức thanh toán",
        loan_apr:"Lãi suất hàng ngày",
        loan_time:"Thời gian cho vay",
        loan_type_1:"Ngày",
        loan_type_2:"Tháng",
        loan_money:"Số tiền vay",
        loan:"Trợ giúp cho vay",
    },
    foot: {
        nav1: "Trang chủ",
        nav2: "Thị trường",
        nav3: "Tiền tệ",
        nav4: "Giao dịch",
        nav5: "Tài sản",
    },
    account: {
        verifyPass: "Xác minh mật khẩu",
        verifySuccess: "Chứng nhận",
        verifyFailed: "Không xác nhận",
        login: "Đăng nhập",
        register_email: "Đăng ký hộp thư",
        register_phone: "Đăng ký di động",
        label_phone: "Số điện thoại",
        place_phone: "Vui lòng nhập số điện thoại",
        label_eMail: "Hộp thư",
        place_eMail: "Vui lòng nhập email của bạn",
        tip_eMail: "Vui lòng nhập đúng email của bạn",
        label_password: "Mật khẩu",
        place_password: "Vui lòng nhập mật khẩu",
        tip_password: "Vui lòng nhập đúng mật khẩu",
        no_account: "Chưa có tài khoản？",
        register: "Đăng ký",
        send: "Gửi CAPTCHA",
        label_code: "Mã xác nhận",
        place_code: "Mã xác nhận",
        tip_code: "Vui lòng nhập CAPTCHA",
        label_invitation: "Mã mời",
        place_invitation: "Vui lòng nhập mã mời",
    },
    assets: {
        title: "Tài sản",
        total_assets: "Tổng tài sản",
        recharge: "Nạp tiền",
        withdraw: "Rút tiền",
        available: "Có sẵn",
        freeze: "Đóng băng",
        total: "Tổng cộng",
        label_bank: "Tiền tệ",
        place_bank: "Vui lòng chọn đồng tiền",
        tip_bank: "Vui lòng chọn đồng tiền",
        label_wallet: "Ví tiền",
        place_wallet: "Vui lòng chọn ví",
        tip_wallet: "Vui lòng chọn ví",
        label_amount: "Số lượng",
        place_amount: "Vui lòng nhập số tiền",
        tip_amount: "Vui lòng nhập số tiền chính xác",
        label_password: "Mật khẩu",
        place_password: "Vui lòng nhập mật khẩu",
        tip_password: "Vui lòng nhập đúng mật khẩu",
        label_balance: "USDT có sẵn",
        all: "Tất cả",
        no_password: "Để đảm bảo an toàn cho tiền của bạn, hãy thiết lập",
        withdraw_psd: "Rút mật khẩu",
        type1: "Tài khoản Coin",
        type2: "Tài khoản fiat",
        type3: "Tài khoản hợp đồng",
        type4: "Tài khoản hợp đồng nhanh",
        type5: "Tài khoản tài chính",
        convert: "Liên kết",
        now_currency: "Tiền tệ hiện tại",
        link_type: "Loại Chain",
        recharge_address: "Địa chỉ nạp tiền",
        copy: "Sao chép địa chỉ",
        palce_amount: "Vui lòng nhập số tiền nạp",
        label_address: "Địa chỉ ví",
        palce_address: "Vui lòng nhập địa chỉ ví",
        submit_process: "Xác nhận",
        tip1: "Không nạp bất kỳ tài sản không phải USDT nào vào địa chỉ trên, nếu không tài sản sẽ không thể phục hồi.",
        tip2: "Nạp tiền USDT chỉ hỗ trợ phương pháp gửi đơn giản, sử dụng phương pháp nạp tiền khác tạm thời không thể nạp tiền, xin bạn thông cảm.",
        tip3: "Sau khi bạn nạp tiền vào địa chỉ trên, cần xác nhận toàn bộ nút mạng, 1 lần xác nhận mạng vào tài khoản, 6 lần xác nhận mạng có thể rút tiền.",
        tip4: "Địa chỉ nạp tiền của bạn không thay đổi thường xuyên và có thể được nạp lại nhiều lần; Nếu có thay đổi, chúng tôi sẽ cố gắng thông báo cho bạn qua thông báo mạng hoặc email.",
        tip5: "Đảm bảo rằng máy tính và trình duyệt của bạn được bảo mật để ngăn chặn thông tin bị giả mạo hoặc rò rỉ.",
        record_title: "Hồ sơ giao dịch",
        label_number: "Số lượng",
        label_record: "Lịch sử",
        label_time: "Thời gian",
        label_price: "Giá cả",
        label_volume: "Số lượng",
        transfer_title: "Chuyển đổi",
        from: "Từ",
        to: "Đến",
        transfer_record: "Ghi chép gạch ngang",
        label_withAddress: "Địa chỉ rút tiền",
        place_withAddress: "Vui lòng thiết lập địa chỉ rút tiền của bạn trước",
        deposit_title: "Khai thác mỏ",
        buy: "Mua hàng",
        sell: "Bán hàng",
        week: "Chu kỳ",
        daily_dividend: "Chia cổ tức hàng ngày",
        deposit_log: "Hồ sơ khai thác cam kết",
        userinfo_nav1: "Chứng nhận",
        userinfo_nav2: "Liên kết chia sẻ của tôi",
        userinfo_nav3: "Mã ủy quyền của tôi",
        userinfo_nav4: "Trung tâm bảo mật",
        userinfo_nav5: "Phương thức thu tiền",
        userinfo_nav6: "Hỗ trợ trực tuyến",
        userinfo_nav7: "Về chúng tôi",
        userinfo_nav8: "Tải app",
        userinfo_nav9: "Trung tâm trợ giúp",
        logout: "Đăng xuất",
        second: "Hợp đồng giây",
        second_title: "Hợp đồng nhanh",
        invite: "Thư mời",
        legal: "Giao dịch tiền tệ",
        ieo: "IEO",
        state0: "Tất cả",
        state1: "Đang bán",
        state2: "Đăng ký",
        state3: "Đã kết thúc",
        history_record: "Lịch sử",
        legal_tab1: "Tôi muốn mua",
        legal_tab2: "Tôi muốn bán",
        transType: "Loại giao dịch",
        order_state: "Trạng thái đặt hàng",
        order_state1: "Chưa hoàn thành",
        order_state2: "Thanh toán",
        order_state3: "Xác nhận trì hoãn",
        order_state4: "Trong Duy Quyền",
        order_state5: "Đã hủy",
        order_state6: "Đã hoàn thành",
        reset: "Đặt lại",
        hand_number: "Số lot giao dịch",
        long: "Làm nhiều",
        short: "Ngắn",
        confirm_order: "Xác nhận đặt hàng",
        type: "Loại",
        multiple_number:"Nhiều",
        now_entrust: "Ủy nhiệm hiện tại",
        all_entrust: "Tất cả ủy thác",
        history_entrust: "Lịch sử ủy thác",
        turnover: "Khối lượng giao dịch",
        operate: "Hoạt động",
        delete: "Xoá",
        order_details: "Chi tiết đặt hàng",
        orderDetails_tab1: "Trong danh sách chờ",
        orderDetails_tab2: "Giao dịch",
        orderDetails_tab3: "Đóng vị trí",
        orderDetails_tab4: "Bỏ lệnh",
        risk_rate: "Tỷ lệ rủi ro",
        total_pl: "Tổng lợi nhuận.",
        closing_position: "Đóng vị trí bằng một cú nhấp chuột",
        position_list: "Danh sách giao dịch",
        order_record: "Lịch sử giao dịch",
        profit_rate: "Hồ sơ đặt hàng",
        buy_long: "Mua tăng",
        buy_short: "Mua giảm",
        trans_mode: "Chế độ giao dịch",
        opening_quantity: "Số lượng vị trí mở",
        opening_number: "Thời gian mở vị trí",
        place_transMount: "Vui lòng nhập hạn mức giao dịch",
        account_balance: "Số dư tài khoản",
        privacy_policy: "Chính sách bảo mật",
        contact_us: "Liên hệ",
        feedback: "Phản hồi người dùng",
        feedback_type: "Loại Feedback",
        feedback_type1: "Rút tiền liên quan",
        feedback_type2: "Blockchain liên quan",
        label_title: "Tiêu đề",
        place_title: "Vui lòng nhập tiêu đề",
        label_cont: "Nội dung phản hồi",
        place_cont: "Vui lòng nhập nội dung",
        reset_password: "Đặt lại mật khẩu",
        personal_center: "Trung tâm cá nhân",
        to_authenticate: "Chứng nhận",
        authenticate_tip1: "·Sau khi xác thực có thể tăng hạn mức rút tiền",
        authenticate_tip2: "· Yêu cầu xác thực tên thật để thực hiện giao dịch tiền mặt",
        payment_method: "Phương thức thu tiền",
        label_reaName: "Tên thật",
        palce_reaName: "Tên thật",
        label_openBank: "Mở tài khoản",
        palce_openBank: "Vui lòng nhập dòng mở tài khoản",
        label_bankNumber: "Số thẻ ngân hàng",
        place_bankNumber: "Vui lòng nhập số thẻ ngân hàng",
        lable_branchBank: "Chi nhánh mở tài khoản",
        place_branchBank: "Vui lòng nhập tên chi nhánh mở tài khoản",
        label_IDnumber: "Số ID",
        place_IDnumber: "Vui lòng nhập số ID",
        label_IDimg: "Ảnh ID ở mặt trước",
        label_IDimg2: "Ảnh ID ở mặt sau",
        tip_IDimg: "Vui lòng tải ảnh mặt sau của ID",
        bankCard: "Thẻ ngân hàng",
        details: "Chi tiết",
        my_authorization_code: "Mã ủy quyền của tôi",
        my_authorization_code_tip: "Mã ủy quyền của tôi",
        login_password: "Mật khẩu đăng nhập",
        trans_password: "Mật khẩu giao dịch",
        modify: "Sửa đổi",
        my_promotional_code: "Mã mời của tôi",
        promotional_code: "Mã mời",
        promotional_code_tip: "Quét mã QR hoặc nhập Mã mời để đăng ký tài khoản",
        copy_link: "Sao chép liên kết mời",
        modify_trans_psd: "Thay đổi mật khẩu giao dịch",
        label_email_verify: "Xác minh hộp thư",
        place_email_verify: "Vui lòng nhập CAPTCHA",
        send: "Gửi",
        email_verify_tip: "CAPTCHA sẽ được gửi đến hộp thư của bạn",
        label_bitTitle: "Cặp giao dịch",
        label_profit: "Lợi nhuận",
    },
    currency: {
        position: "Giữ vị trí",
        drawer_title: "Giao dịch tiền tệ",
        now: "Hiện tại",
        open: "Mở cửa",
        volume: "Khối lượng",
        height: "Cao nhất",
        low: "Thấp nhất",
        buy: "Mua nhiều",
        sell: "Bán khống",
        name: "Tên",
        direction: "Hướng dẫn",
        crm_order: "Xác nhận đơn hàng",
        now_price: "Giá hiện tại",
        type_time: "Thời gian đóng cửa (lợi nhuận trượt bên trái cao hơn)",
        second: "Giây",
        buy_amount: "Số tiền mua",
        available: "Có sẵn",
        service_fee: "Phí xử lý",
        min_number: "Tối thiểu",
        errorTip1: "Số tiền nhập sai",
        errorTip2: "Không đủ số dư có sẵn",
        auto_jump: "Lệnh nhảy tự động sau",
        keep: "Tiếp tục đặt hàng",
        revenue: "Lợi nhuận dự kiến",
    },
    home: {
        quick: "Nhanh chóng rút tiền xu",
        support: "Hỗ trợ",
        nav1: "Chia sẻ",
        nav2: "Ví tiền",
        nav3: "Thông tin",
        nav4: "Hướng dẫn",
        nav5: "Dịch vụ",
        nav6: "Khai thác mỏ",
        nav7: "Về chúng tôi",
        nav8: "Yêu cầu mua đồng xu mới",
        increase: "Bảng tăng trưởng",
        volume: "Khối lượng",
        table_name: "Tên",
        table_lastPrice: "Giá mới nhất",
        table_increase: "Tăng giảm",
        table_volume: "Khối lượng giao dịch",
    },
    lockming: {
        profit: "Gần đây (thu nhập hàng ngày)",
        cycle: "Chu kỳ tài chính",
        limit: "Giới hạn duy nhất",
        dividend_time: "Thời gian phân phối",
        every_day: "Hàng ngày",
        funds: "Tiền ký quỹ",
        back: "Hết hạn trả về",
        ransom: "Mua lại sớm",
        label_amount: "Số tiền cam kết",
        place_amount: "Vui lòng nhập...",
        available: "Có sẵn",
        expect_income: "Lợi nhuận dự kiến",
        title: "Khai thác mỏ Lockout",
        tip: "Khai thác mỏ không ngừng",
        label_today_income: "Dự kiến lợi nhuận hôm nay",
        label_total_income: "Thu nhập tích lũy",
        label_order: "Đặt hàng Hosting",
        label_number: "Số lượng đơn",
        daily_return: "Năng suất hàng ngày",
        subscribe: "Yêu cầu mua hàng",
        position: "Giữ vị trí",
        label_state: "Trạng thái",
        subscribe_time: "Thời gian mua hàng",
        expiration_time: "Thời gian hết hạn",
        income: "Lợi nhuận",
        errorTip1: "Vui lòng nhập số tiền",
        tip_tit: "Bạn có chắc chắn muốn thoát sớm?",
    },
    market: {
        spots: "Hàng tồn kho",
        contract: "Hợp đồng",
        currency_trans: "Giao dịch tiền tệ",
        increase: "Bảng tăng trưởng",
        volume: "Khối lượng",
        label_name: "Tên",
        label_lastPrice: "Giá mới nhất",
        table_increase: "Tăng giảm",
        table_volume: "Khối lượng giao dịch",
    },
    my: {
        details: "Chi tiết",
        help: "Trung tâm trợ giúp",
        information: "Thông tin",
        language: "Chuyển đổi ngôn ngữ",
        password: {
            account_title: "Thay đổi mật khẩu",
            withdraw_title: "Thay đổi mật khẩu",
            label_oldPsd: "Mật khẩu cũ",
            place_oldPsd: "Vui lòng nhập mật khẩu cũ",
            label_newPsd: "Mật khẩu mới",
            place_newPsd: "Vui lòng nhập mật khẩu mới",
            label_crmPsd: "Xác nhận mật khẩu",
            place_crmPsd: "Vui lòng nhập lại mật khẩu mới",
            withdraw_tip: "Nếu mật khẩu rút tiền chưa được thiết lập, vui lòng để trống",
        },
        address: {
            title: "Địa chỉ ví",
            account_number: "Số tài khoản",
            add_btn: "Thêm địa chỉ",
            label_type: "Chọn loại",
            place_type: "Vui lòng chọn loại",
            label_number: "Số tài khoản",
            place_number: "Vui lòng nhập số tài khoản",
        },
        history: {
            title: "Lịch sử",
            recharge_record: "Hồ sơ nạp tiền",
            withdraw_record: "Hồ sơ rút tiền",
            recharge: "Nạp tiền",
            withdraw: "Rút tiền",
            number: "Số lượng",
            currency: "Tiền tệ",
            state: "Trạng thái",
            state0: "Đang được xem xét",
            state1: "Đã hoàn thành",
            state2: "Bị từ chối",
        },
        identity: {
            title: "Chứng nhận",
            label_country: "Chọn quốc tịch",
            place_country: "Vui lòng chọn quốc tịch",
            label_name: "Tên",
            place_name: "Vui lòng nhập tên của bạn",
            label_id: "Số giấy tờ",
            place_id: "Vui lòng nhập ID/bằng lái xe/số hộ chiếu của bạn",
        },
        my: {
            list1: "Chứng nhận",
            list2: "Quản lý đơn hàng",
            list3: "Hồ sơ tiền",
            list4: "Địa chỉ ví",
            list5: "Chuyển đổi ngôn ngữ",
            list6: "Mời bạn bè",
            list7: "Dịch vụ",
            list8: "Hướng dẫn",
            list9: "Thay đổi mật khẩu",
            logout: "Đăng xuất",
        },
        order: {
            title: "Giữ vị trí",
            direction: "Hướng dẫn",
            sell_price: "Đơn giá dưới",
            number: "Số lượng",
            type: "Thời gian đóng cửa",
            buy_price: "Giá mua",
            profit: "Lợi nhuận và lỗ",
            sell_time: "Thời gian bán",
        },
        share: {
            title: "Mời bạn bè",
            save_ewm: "Lưu mã QR",
            invite_link: "Liên kết mời",
            copy_address: "Sao chép địa chỉ",
            invite_code: "Mã đăng ký",
            copy_code: "Sao chép nội dung",
            tip: "Đăng ký bằng mã QR hoặc liên kết",
        },
    },
    transaction: {
        spots: "Hàng tồn kho",
        contract: "Hợp đồng",
        currency_trans: "Giao dịch tiền tệ",
        exchange: "Nhấp nháy",
        sustainable: "Vĩnh viễn",
        warehouse: "Theo kho",
        jump_lever: "Đòn bẩy nhảy",
        sell: "Bán hàng",
        buy: "Mua vào",
        available: "Có sẵn",
        check_price: "Giới hạn",
        market_price: "Giá thị trường",
        number: "Số lượng",
        max: "Tối đa",
        bail: "Ký quỹ",
        buy_long: "Mua nhiều",
        sell_short: "Bán khống",
        last_price: "Giá mới nhất",
        now_entrust: "Lịch sử giữ kho",
        now_position: "Vị trí hiện tại",
        table_amount: "Số tiền rút ra",
        is_reduce: "Chỉ giảm kho",
        shi: "Vâng",
        fou: "Không",
        position_number: "Số lượng vị trí giữ",
        is_profit: "Lợi nhuận",
        wei: "Không",
        open_price: "Giá mở vị trí",
        mark_price: "Đánh dấu giá",
        flat_price: "Giá phẳng mạnh",
        trim_lever: "Điều chỉnh đòn bẩy",
        flat_store: "Đóng vị trí",
        trim_bail: "Điều chỉnh Margin",
        reduce_bail: "Giảm Margin",
        now_bail: "Ký quỹ vị trí hiện tại",
        max_add: "Tối đa có thể tăng",
        refer_flat: "Tham khảo tương đương mạnh sau khi điều chỉnh",
        expect_profit: "Lợi nhuận và lỗ dự kiến",
    },
    common: {
        confirm: "Xác nhận",
        Cancel: "Hủy bỏ",
        all: "Tất cả",
        close: "Hủy bỏ",
        copy_success: "Sao chép thành công！",
        copy_error: "Sao chép thất bại！",
        place_input: "Vui lòng nhập nội dung",
        more: "Thêm",
        main: "Sơ đồ chính",
        sub: "Sơ đồ phụ",
        success: "Thành công！",
        cancel_tip: "Bạn có chắc chắn muốn hủy bỏ??",
        notice: "Thông báo",
        logout_tip: "Xác nhận thoát？",
        tip: "Mẹo",
        day: "Ngày",
        place_inner: "Vui lòng nhập nội dung",
        fail_banner: "Số dư không đủ",
        empty: "Không có dữ liệu.",
    },
    message: {
        cancel_order: "Hủy bỏ ủy quyền?",
        stop_out: "Bắt buộc đóng vị trí?",
    },
    params: {
        transType0: "Tiền xu",
        transType1: "Hợp đồng",
        directionType0: "Mua vào",
        directionType1: "Bán hàng",
        lockStateType0: "Đang chạy",
        lockStateType1: "Đã kết thúc",
        lockStateType2: "Đã hủy",
    },
    city: {
        albania: "Albania",
        algeria: "An- giê- riName",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Châu Úc",
        pakistan: "Việt Nam",
        austria: "Áo",
        bahrain: "Bahrain",
        belgium: "Bỉ",
        bosnia_and_Herzegovina: "Bosnia và Herzegovina",
        brazil: "Việt Nam",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Campuchia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chi- lê",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Síp",
        czech_Republic: "Cộng hòa Séc",
        denmark: "Đan Mạch",
        dominican_Republic: "Cộng hòa Dominica",
        egypt: "Ai Cập",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Phần Lan",
        france: "Pháp",
        georgia: "Georgia",
        germany: "Đức",
        ghana: "Ghana",
        greece: "Hy Lạp",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hồng Kông, Trung Quốc",
        hungary: "Việt Nam",
        iceland: "Iceland",
        ireland: "Việt Nam",
        italy: "Ý",
        india: "Ấn độ",
        indonesia: "Việt Nam",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Nhật Bản",
        kazakstan: "Cộng hòa Kazakhstan",
        kenya: "Việt Nam",
        korea: "Hàn Quốc",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Lào",
        latvia: "Latvia",
        lithuania: "Li- tu- a- niName",
        luxembourg: "Luxembourg",
        macao_China: "Ma Cao, Trung Quốc",
        macedonia: "Ma- xê- đô- ni- aName",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mông Cổ",
        montenegro: "Cộng hòa Montenegro",
        morocco: "Ma- rốc",
        myanmar: "Miến Điện",
        netherlands: "Hà Lan",
        new_Zealand: "New Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Na Uy",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama.",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Ba Lan",
        portugal: "Bồ Đào Nha葡萄牙",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Rumani",
        russia: "Nga",
        republic_of_Trinidad_and_Tobago: "Cộng hòa Trinidad và Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Ả Rập Saudi",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Xlô- viName",
        south_Africa: "Nam Phi",
        spain: "Tây Ban Nha",
        sri_Lanka: "Sri Lanka",
        sweden: "Thụy Điển",
        switzerland: "Thụy Sĩ",
        taiwan_China: "Đài Loan taiwan_China",
        tajikistan: "Cộng hòa Tajik",
        tanzania: "Tanzania",
        thailand: "Thái Lan",
        turkey: "Thổ Nhĩ Kỳ",
        turkmenistan: "Cộng hòa Turkmen",
        ukraine: "Ukraine",
        united_Arab_Emirates: "Các Tiểu vương quốc Ả Rập Thống nhất",
        united_Kingdom: "United Kingdom",
        united_States: "Hoa Kỳ",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Bénin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "Quần đảo Virgin thuộc Anh",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Quần đảo Cayman",
        central_African_Republic: "Cộng hòa Trung Phi",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: "Congo (bằng tiếng Anh).",
        democratic_Republic_of_the_Congo: "Congo (Kim)",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Guinea Xích đạo",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: "Đảo Man",
        cote_d_Ivoire: "Bờ Biển Ngà",
        jamaica: "Nha- mê- caName",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Li- bi- aName",
        madagascar: "Madagascar",
        malawi: "Ma- la- uy",
        maldives: "Maldives",
        mali: "Ma- li- ",
        mauritania: "Ma- ri- tanhName",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Niger",
        north_Korea: "Bắc Triều Tiên",
        reunion: "Réunion",
        san_Marino: "San Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Thụy Sĩ",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "Quần đảo Virgin thuộc Mỹ",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "Trung Quốc",
    }
};
